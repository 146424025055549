<div class="vue-images">
  {#if isShow && images.length > 0}
    <div id="lightbox" class="lightbox">
      <Fancybox
        image={images[index]}
        {images}
        bind:index
        bind:playing
        bind:isShow />
      <Paginator {images} bind:index bind:playing />
    </div>
  {:else}
    <Gallery {images} bind:index bind:isShow />
  {/if}
</div>

<script>
  // import 'animate.css/animate.min.css';
  // import '~/plugins/lazysizesPlg';
  import Gallery from "./Gallery.svelte";
  import Fancybox from "./Fancybox.svelte";
  import Paginator from "./Paginator.svelte";

  export let imgs;
  let playing = false;
  let images;
  let isShow;
  let index = null;

  let touchPoint = {
    prev: 0,
    now: 0,
  };
  $: {
    console.warn("lightBox:", { index, isShow, playing });
  }
  const filterImages = (imgs) => {
    let retArr = [];
    let idx = 0;

    // Filter to remove undefined items
    imgs.forEach((item, i) => {
      if (item) {
        item["index"] = idx++;
        item["isActive"] = false;
        item["caption"] = item.caption ? item.caption : "";
        retArr.push(item);
      }
    });

    for (let i = 0, len = retArr.length; i < len; i++) {
      retArr[i]["total"] = len;
    }

    return retArr;
  };
  $: {
    images = filterImages(imgs);
    console.log("images:", imgs);
  }
</script>

<style lang="postcss">
  :root {
    --headerSize: 40px;
    --imageSize: calc(100vh - var(--headerSize) - var(--pagSize));
    --pagSize: 80px;
  }

  @media (max-width: 500px) {
    :root {
      --pagSize: 40px;
      --headerSize: 20px;
    }
  }
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    font-size: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: grid;
    grid-template-rows: minmax(0, 1fr) var(--pagSize);
  }
</style>
