<div class="eventsdetails">
  {#each events as event}
    <div>
      <EventDetail {event} />
    </div>
  {/each}
</div>

<script>
  import { onMount } from "svelte";
  import { format, parseISO } from "date-fns";
  import EventDetail from "./_EventDetail.svelte";
  const today = format(new Date(), "yyyy-MM-dd");
  const never = "9999-99-99";
  const now = "0000-00-00";
  export let where = "E";
  let events = [];

  onMount(async () => {
    let res = await fetch(`${process.env.API_SERVER}/events/all`);
    let data = await res.json();
    console.log("eventsData", data);
    events = data.filter((event) => {
      event.displays = event.displays.filter(
        (d) => d.where === where && d.active
      );
      return event.displays.length > 0;
    });

    events = events.filter((evnt) => {
      let eventEnd = evnt.end || evnt.start;

      let { start, end, active } = evnt.displays[0];
      let showing =
        active &&
        today >= (start ?? today) &&
        today <= (end ?? eventEnd ?? today);

      return showing;
    });
    console.log("eventsData", events);
  });
</script>

<style lang="postcss">
  .eventsdetails {
    box-sizing: border-box;
    width: 100%;
    border-bottom: 3px solid #c99;

    padding: 0;
  }
</style>
