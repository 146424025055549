<svg
  {fill}
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="100%"
  height="100%"
  viewBox="0 0 357 357"
  style="enable-background:new 0 0 357 357;"
  xml:space="preserve">
  <path d="M25.5,357h102V0h-102V357z M229.5,0v357h102V0H229.5z" />
</svg>

<script>
  export let fill = "#ccc";
</script>
