<template>
  <div class="slideshow" style="font-size:2rem; text-align:center;">
    <img src={`/images/${image}.svg`} alt="spinner" />
  </div>
</template>

<script>
  export let typ = 3;
  let image;
  $: image = ["", "gears", "reload", "spin"][typ];
</script>
