<div class="paginator">
  <div
    class:hide={thumbsStart <= 0}
    class="arrow left"
    on:click|stopPropagation={() => adjustThumbIndex(-1)}
  >
    <IconArrowLeft />
  </div>
  {#each activeImages ?? [] as item, i (item.index)}
    <div
      class="thumbnail-wrapper"
      class:active={index === thumbsStart + i}
      on:click|stopPropagation={() => setActive(item.index)}
    >
      {#if !isMove || isMove}
        <img
          src={item.imageURL}
          srcset={item.srcset}
          class="thumbnail"
          alt="."
        />
        <span class="index">{item.index}</span>
      {/if}
    </div>
  {/each}
  <div
    class:hide={thumbsStart + noThumbs >= images.length}
    class="arrow right"
    on:click|stopPropagation={() => adjustThumbIndex(+1)}
  >
    <IconArrowRight fill="#ccc" />
  </div>
</div>

<script>
  import IconArrowRight from "./arrowRight.svelte";
  import IconArrowLeft from "./arrowLeft.svelte";

  import Thumbnail from "./Thumbnail.svelte";
  export let images;
  export let index;
  export let playing;
  let isMove = false;
  let noThumbs;
  let activeImages;
  let thumbsStart = 0;
  let oldIndex = null;
  $: if (index !== oldIndex) {
    thumbsStart = Math.min(
      Math.max(index - Math.floor(noThumbs / 2), 0),
      images.length - noThumbs
    );
    console.log("Paginator", { thumbsStart, noThumbs });
    oldIndex = index;
  }

  $: {
    const tWidth = window.innerWidth > 500 ? 80 : 40;
    noThumbs = Math.min(
      images.length,
      Math.round(window.innerWidth / tWidth) - 1
    );
    console.log("Paginator", {
      noThumbs,
      noImages: images.length,
      window: window.innerWidth,
    });
  }

  $: {
    activeImages = images.slice(thumbsStart, thumbsStart + noThumbs);
    console.warn("activeImages", index, thumbsStart, activeImages);
  }
  // $: {
  //   index = setIndex(activeIndex);
  //   setActive(activeIndex);
  //   console.log("paginator mounted", window.innerWidth);
  // }
  // onMount(() => {
  //   index = setIndex(activeIndex);
  //   setActive(activeIndex);
  // });
  let setIndex = (idx) => {
    return Math.min(
      Math.max(0, idx - Math.floor(noThumbs / 2 - 0.5)),
      images.length - noThumbs
    );
  };
  let adjustThumbIndex = (direction) => {
    let oldStart = thumbsStart;
    let newStart = thumbsStart + direction * (noThumbs - 1);
    thumbsStart = Math.max(0, Math.min(newStart, images.length - noThumbs));
    console.warn(
      "paginator new Thumb start:",
      thumbsStart,
      oldStart,
      "==>",
      newStart
    );
  };
  let setActive = (idx) => {
    console.log("Paginator setActive:", { idx, index, playing });
    if (idx === index) return;
    index = idx;
    playing = false;
  };
</script>

<style lang="postcss">
  /* :root {
    --fontSize: 1em;
    --albumWidth: 12.5em;
    --liWidth: 11em;
  }

  @media (max-width: 500px) {
    .paginator {
      transform: scale(0.5, 0.5);
      --fontSize: 0.7em;
      --albumWidth: 9em;
      --liWidth: 8.5em;
    }
  } */
  .paginator {
    width: 100%;
    min-width: 300px;

    display: flex;
    justify-content: center;
    left: 0;
    bottom: 10px;
    text-align: center;
    margin: 0 auto;
    & .arrow {
      display: inline-block;

      vertical-align: top;
      height: var(--pagSize);
      width: calc(0.5 * var(--pagSize));

      border: thin solid #ccc;

      cursor: pointer;

      &.hide {
        opacity: 0;
        cursor: none;
      }
    }

    & .thumbnail-wrapper {
      display: inline-block;
      position: relative;
      & .index {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 15px;
        height: 15px;
        background-color: white;
        color: black;
        z-index: 10;
        font-size: 10px;
        visibility: hidden;
      }
      &.active {
        box-shadow: inset 0 0 0 2px #fff;
      }
    }
  }
  .thumbnail {
    display: inline-block;
    margin: 2px;
    width: calc(var(--pagSize) * (7 / 8));
    height: calc(var(--pagSize) * (7 / 8));
    border-radius: 2px;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    overflow: hidden;
    object-fit: cover;
  }
</style>
