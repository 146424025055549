<div class="gallery">
  <div class=" brownBox current">
    {album?.title}
  </div>
  <div class="buttons">
    <button class="showIndex current brownBox" on:click={pop}
      >⬅️&nbsp;Back</button
    >
    {#if $isUploader}
      <button
        ord="secondary"
        on:click={replace(`/hk/gallery/maintAlbum/${albumId}`)}
        >Maintain Album</button
      >
    {/if}
  </div>
  <div class="pictures">
    {#if pictures?.length}
      <Lightbox imgs={pictures} />
      <!-- <a class="addMore brownBox" href="/#/gallery/upload">Add Photos </a> -->
    {/if}
  </div>
</div>

<script>
  import { onMount } from "svelte";
  import { pop, replace } from "svelte-spa-router";
  import {
    getGalleryDataF,
    getGalleryDataURL,
    expandSrcset,
  } from "@components/galleryMixin.mjs";
  import { isUploader } from "@store/authentication";
  import Lightbox from "@components/gallery/Lightbox.svelte";
  // var Lightbox;
  export let params;
  $: albumId = params.albumId;

  let album = {};
  let pictures = [];

  $: console.warn("album:", album);

  onMount(async () => {
    album = await getGalleryDataF("getAlbum", albumId);
    album.title = album.title.replace(/&amp;/gi, "&");
    const directory = album.directory;
    console.log("getAlbums data", album, directory);
    pictures = album.pictures
      .filter((p) => p.srcset)
      .map((pic) => {
        const imageUrl = getGalleryDataURL(directory, pic.filename);
        const srcset = expandSrcset(pic.srcset);
        return { ...pic, imageUrl, srcset };
      });
    console.log("getAlbums pictures", album, pictures);
  });
</script>

<style css="postcss">
  :root {
    --fontSize: 1em;
  }

  @media (max-width: 500px) {
    :root {
      --fontSize: 0.7em;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
  }
  .gallery {
    /* display: flex;
    flex-direction: row; */
    box-sizing: border-box;
    padding: 0.2em;
  }

  .pictures {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .brownBox {
    width: fit-content;
    max-width: none;
    min-width: 5em;
    font-size: var(--fontSize);

    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    border-width: 3px;
    border-radius: 0.56em;
    border-style: double;
    border-color: #444;
    padding: 0.2em 1.2em 0.2em 0.7em;
    margin: 0.2em 0 0 0;
    padding-bottom: 5px;
    text-decoration: none;
    color: #444;
    background-color: rgba(241, 222, 174, 0.98);
    margin-bottom: 5px;
    display: block;
  }
</style>
