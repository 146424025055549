echo.log(echo.bgMagenta.white.lighter.large("hello world"));
echo.log(echo.bgBlack.white.bold.larger("hello world"));
echo.log(echo.bgCyan.black.largest.asTag("hello world"));
echo.log(echo.bgMagenta.white.lighter.massive.asTag("hello world"));
echo.log(echo.cyan.bgBlack("hello world"));
echo.log(echo.asWarning.strikethrough("hello world"));
echo.log(echo.asAlert.underline("hello world"));
echo.log(echo.asMarquee("hello world out there"));
echo.log(echo.asTag("hello world asTag"));
echo
  .defineRootStyler("color: red ;")
  .defineStyler("asTag2", echo.bold.padded.rounded);
echo.log(echo.asTag("hello world asTag"));
echo.log(echo.asTag2("hello world asTag"));
// Using the extended colors.
echo.log(
  echo.bgDodgerblue.white.largest.asTag.lighter("Hot"),
  echo.bgDimgray.white.largest.asTag("Diggity"),
  echo.bgRebeccapurple.white.largest.asTag.lighter("Dog"),
  echo.bgYellowgreen.white.largest.asTag("That's"),
  echo.bgTomato.white.largest.asTag.lighter("Cool!")
);
// And, of course, thesestylers can be mixed with "raw" values as well.
echo.log("Raw value", echo.bgGray.white.asTag("Styled value"), ["Raw value"]);
let log = (...rest) => echo.log(echo.white.bgBlue.bold("EventEdit"), ...rest);
log("hot", "Diggity", "do", "dah");

log = (a, ...rest) =>
  echo.log(echo.blue.bold("testEcho"), echo.white.bgBlue.asTag(a), ...rest);
log("hot", "Diggity", "do", "dah");
