<div class="layout-fixed">
  <div class="col-main last">
    <form name="Frm1" autocomplete="off" onsubmit="return false;">
      <div class="eventinput">
        <div class="line">
          <div label="id">
            <input id="No" size="2" bind:value={eventData.id} disabled />
          </div>
          <div label="title">
            <input
              bind:value={eventData.title}
              size="60"
              placeholder="title..."
            />
          </div>
        </div>
        <div class="line">
          <div label="">Multi Day?</div>
          <input bind:checked={multiday} type="checkbox" />
          <div label={multiday ? "start" : "date"}>
            <DatePicker bind:selected={eventData.start} />
            <!-- <input bind:value={eventData.start} type="date" /> -->
          </div>
          {#if multiday}
            <span>until</span>
            <div label="end">
              <DatePicker bind:selected={eventData.end} />
            </div>
          {/if}
        </div>
        <div class="line">
          <div label="location">
            <input bind:value={eventData.location} size="40" />
          </div>
          <div label="time">
            <input bind:value={eventData.time} size="10" type="time" />
          </div>
        </div>
        <div class="line">
          <div label="description">
            <textarea
              bind:value={eventData.description}
              use:text_area_resize
              cols="60"
            />
          </div>
        </div>
        <div class="block">
          <div class="head">Display on</div>
          {#each eventData.displays as { active, where, start, end }}
            <EditDisplay bind:active bind:start bind:end {eventEnd} {where} />
          {/each}
        </div>
        <input type="button" on:click={delEvent} value="Delete" />
        <input type="button" on:click={cloneEvent} value="Clone" />
        {#if eventData.dirty}
          <input type="button" on:click={save} value="Save" />
          <input type="button" on:click={reset} value="Reset" />
        {/if}
      </div>
    </form>
    <div id="preview">
      <h3>Preview</h3>
      <div>
        <div class="innertube">
          <EventDetail event={eventData} />
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  import EventDetail from "../events/_EventDetail.svelte";
  import EditDisplay from "./_EventEditDisplay.svelte";
  import DatePicker from "./DatePicker/DatePicker.svelte";
  import { text_area_resize } from "./resizeable-textarea.js";
  import { format, parseISO } from "date-fns";
  import { marked } from "marked";
  import _ from "lodash";

  export let eventData;
  export let delEvent;
  export let saveEvent;
  export let cloneEvent;

  const log = (a, ...rest) =>
    echo.log(echo.blue.bold("EventEdit"), echo.white.bgBlue.asTag(a), ...rest);
  let origData = null;
  let multiday = false;
  const isDirty = (evnt) => {
    const { dirty, unsaved, ...data } = evnt;
    const eq = _.isEqual(data, origData);
    log("isDirty", data, origData, eq);
    return !_.isEqual(data, origData);
  };
  $: if (eventData?.end) multiday = true;
  $: log("edit eventData id changed", eventData.id);
  $: eventEnd = eventData.end || eventData.start;
  $: eventData.dirty = isDirty(eventData);
  $: if (eventData.id !== origData?.id) {
    fetch(`${process.env.API_SERVER}/events/${eventData.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          origData = data;
          eventData.dirty = isDirty(eventData);
        } else {
          origData = { id: eventData.id, dirty: true, unsaved: true };
        }
      })
      .then(() => {
        log("changed event", origData, eventData.id, eventData);
      });
  }
  const deleteMe = () => {
    delEvent(eventData.id);
  };
  const save = async () => {
    await saveEvent();
    origData = {};
    // updateEvent(eventId, eventData);
  };
  const reset = async () => {
    Object.entries(origData).forEach(
      ([key, value]) => (eventData[key] = _.cloneDeep(value))
    );
  };
</script>

<style lang="postcss">
  input {
    font-size: 1.1em;
  }
  #preview {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
  }
  #preview h3 {
    color: brown;
  }

  .innertube {
    max-width: 340px;
    border: 3px solid #c99;
    margin-left: 1em;
  }
  .line {
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
  }
  .line div {
    padding: 0 5px;
    position: relative;
  }
  .block {
    border: thin solid black;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .block .head {
    background-color: bisque;
    border-bottom: thin solid black;
    overflow: hidden;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  input {
    padding: 0 5px;
    position: relative;
  }

  [label]:after {
    content: attr(label);
    position: absolute;
    display: inline-block;
    left: 1em;
    top: -8px;
    background-color: white;
    font-size: 0.7em;
    color: slategray;
  }
</style>
