<div class="grid">
  <div class="form">
    <!-- <Header /> -->
    <div class="frame">
      <EventPick {eventsData} bind:eventId {addEvent} />
      {#if eventData}
        <EventEdit {...handlers} bind:eventData {eventId} />
      {/if}
    </div>
  </div>
</div>

<script>
  import { onMount } from "svelte";
  import EventPick from "./_EventPick.svelte";
  import EventEdit from "./_EventEdit.svelte";
  import { getVisitorId, getAuthseq } from "@store/authentication";

  import _ from "lodash";
  import { format } from "date-fns/fp";

  const fmtYYMMDD = format("yyyy-MM-dd");
  const log = (a, ...rest) =>
    echo.log(
      echo.cyan.bold("MaintEventProg"),
      echo.black.bgCyan.asTag(a),
      ...rest
    );

  let eventId = null,
    eventsData = [];

  onMount(async () => {
    let res = await fetch(`${process.env.API_SERVER}/events/all`);
    let data = await res.json();

    log("eventsData", res, data);
    eventsData = _.sortBy(data || [], "id");
  });
  const findEvent = (id) => {
    const res = eventsData.find((evnt) => evnt.id === id);
    log(" event changed", eventId, eventData);
    return res;
  };
  $: eventData = eventsData.find((evnt) => evnt.id === eventId);

  const delEvent = async () => {
    log("deleting", eventId, eventData);
    eventsData = eventsData.filter((e) => e.id !== eventId);
    if (!eventData.new) {
      const res = await fetch(`${process.env.API_SERVER}/events/${eventId}`, {
        method: "DELETE",
        cache: "no-cache",
        // credentials: "include",
      });
      let body = await res.json();
      log(`postRequest events/${eventId} returned:`, body);
      return body;
    }
    eventId = null;
  };
  const addEvent = () => {
    let dn = _.max(Object.keys(parseInt(eventsData))) || 0;
    //var w = new Object();
    let nextNo = (_.max(eventsData.map((ev) => parseInt(ev.id))) || 0) + 1;

    var w = {
      id: nextNo,
      displays: [
        { eventId: nextNo, where: "E", start: null, end: null, active: false },
        { eventId: nextNo, where: "H", start: null, end: null, active: false },
      ],
      title: null,
      start: null,
      end: null,
      location: null,
      time: null,
      description: "",
      unsaved: true,
    };
    eventsData.push(w);
    eventId = nextNo;
    // saveEvents();
  };
  const cloneEvent = () => {
    let nextNo = (_.max(eventsData.map((ev) => parseInt(ev.id))) || 0) + 1;
    let evnt = _.cloneDeep(eventData);
    evnt.unsaved = true;
    evnt.id = nextNo;
    evnt.displays.forEach((d) => (d.eventId = nextNo));
    eventsData = [...eventsData, evnt];
    eventId = nextNo;
    // saveEvents();
  };
  const dispDate = (date) => {
    if (!date) return "";
    return fmtYYMMDD(new Date(date));
  };
  const saveEvent = async () => {
    try {
      log(`postRequest`, eventData);
      const res = await fetch(
        `${process.env.API_SERVER}/events/save/${eventId}`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": getVisitorId(),
            authseq: getAuthseq(),
          },
          body: JSON.stringify(eventData), // body data type must match "Content-Type" header
        }
      );
      let body = await res.json();
      eventData.unsaved = false;
      eventData.dirty = false;
      log(`postRequest events/save returned:`, body);
      return body;
    } catch (error) {
      console.log(error);
    }
  };
  const handlers = { saveEvent, addEvent, delEvent, cloneEvent };
</script>

<style lang="postcss">
  .frame {
    display: flex;
    flex-direction: row;
  }
  @media print {
    .grid {
      width: 650px;
    }
  }
</style>
