<div class="authentication" class:signin>
  {#if state === 2}
    <div class="name">
      {$authState.name} ({$authState.identifier})
      <button on:click={handleLogout} class="button" type="warning" size="mini"
        >Log out</button
      >
    </div>
  {/if}
  {#if state !== 2}
    <button on:click={openDialog} class="button">Log in</button>
  {/if}
  {#if showDialog}
    <Dialog
      bind:dialog
      on:close={() => (showDialog = false)}
      title={`Log-in`}
      style="min-width: 400px;font-size:14px;"
    >
      {#if state === 0}
        <div v-if="state===0">
          This page is only available to {title} of the walking club.
          <h4>Authentication required</h4>
        </div>
        <div v-show="state===0" class="identifier">
          <div class="inputBox">
            <div>Enter your email address and press Enter</div>
            <input
              bind:value={xIdentifier}
              on:change={handleIdentifier}
              placeholder="email"
              autocomplete="email"
            />
          </div>
        </div>
      {/if}
      {#if state === 1}
        <div class="inputBox">
          <div>
            You have been sent a verification code via {$authState.via}. Please
            enter it here and press enter
          </div>
          <input bind:value={xVerification} on:change={handleVerification} />
        </div>
        <button on:click={handleResend} size="mini">resend code</button>
        <button on:click={handleLogout} size="mini">reset</button>
      {/if}
      {#if $authState.error}
        <div class="error">{$authState.error}</div>
      {/if}
    </Dialog>
  {/if}
</div>
<svelte:head>
  <link rel="stylesheet" href="/css/dialog-polyfill.css" />
</svelte:head>

<script>
  export let roleReq;
  import { onMount, tick } from "svelte";
  import {
    authState,
    getAuth,
    checkAuth,
    sendRequest,
    requestSignin,
  } from "../store/authentication";
  import Dialog from "@components/hk/Dialog.svelte";
  // biome-ignore lint/style/useConst: dialog must be writable
  let dialog = null;
  let showDialog = false;
  async function openDialog() {
    showDialog = true;
    requestSignin();
    await tick(); // waits for dialog to be set
    dialog.showModal();
  }

  let via;
  let error;

  let xIdentifier = "";
  let xVerification = "";

  onMount(async () => {
    // get current authentication status
    getAuth();
  });
  $: console.log("authState", $authState);
  $: state = $authState?.state;
  $: if (state === 2) showDialog = false;
  $: signin = state >= 0;
  $: title = ["member", "committee members", "administrators"][roleReq];

  async function handleIdentifier() {
    if (xIdentifier.indexOf("@") === -1) {
      xIdentifier = xIdentifier.replace(/[ -.]/, "");
    }
    console.log("handleIdentifier", xIdentifier);
    await sendRequest(["checkIdentifier", xIdentifier, roleReq]);
  }
  async function handleVerification() {
    await sendRequest(["checkVerfication", xVerification]);
  }
  async function handleResend() {
    await sendRequest(["checkIdentifier", $authState.identifier, roleReq]);
  }
  async function handleLogout() {
    xIdentifier = "";
    xVerification = "";
    await sendRequest(["logout"]);
  }
</script>

<style lang="postcss">
  .authentication {
    box-sizing: border-box;
    border: thin solid #444444;
    /* width: 100%; */
    border-radius: 0.2rem;
    padding: 0.2em 1em;
    & h4 {
      color: red;
      padding: 0.3em 0;
    }
    & .inputBox {
      border: thin solid #ff8888;
      border-radius: 0.3em;
      background-color: #f6d0d0;
      width: 40%;
      min-width: 240px;
      padding: 0.5em;
      /*display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;*/
      & input {
        display: block;
        width: 100%;
        margin-top: 0.5em;
        background-color: white;
        border: thin solid #888888;
        border-radius: 0.3em;
        font-size: 1.1em;
      }
    }
    & button {
      /* font-size: 10px; */
      width: auto;
      margin: 0;
      padding: 0;
      /*border-radius: 0.2em;*/
    }
  }
  .signin {
    background-color: rgb(242, 218, 200);
  }
  .error {
    color: red;
    font-weight: bold;
  }
  .name {
    /* width: 100%; */
    text-align: right;
    display: flex;
    flex-direction: row;
    /* font-size: 10px; */
  }
  .button {
    display: inline-block;
    margin: 0;
    padding: 2px;
  }
</style>
