<div class="gallery0">
  <div class="title">
    <div class=" brownBox current">
      {album?.title}
    </div>
  </div>
  <div class="buttons">
    <button class="showIndex current brownBox" on:click={pop}
      >⬅️&nbsp;Back</button
    >

    <button
      ord="secondary"
      on:click={() => replace(`/gallery/album/${albumId}`)}>View Album</button
    >

    <button on:click={selectAll} ord="tertiary"
      >Select All &#9745; &#9745; &#9745;</button
    >
    <button on:click={clearAll} ord="tertiary">Clear Selection</button>
  </div>

  <div class="pictures">
    <div class="gallery">
      {#each pictures as item, indx}
        <button
          type="button"
          class="wrapper"
          class:blur={noSelected > 0 && !item.selected}
          on:click={() => pictureClicked(indx)}
        >
          <div class="pic">
            {#if item.hidden}
              <m-icon name="ban" />
            {/if}
            <!-- <img :data-src="item.imageUrl" class="lazyload" @click="setActive(index)"> -->
            <img
              data-src={item.imageUrl}
              data-srcset={mapAlbum(item.srcset)}
              src={item.imageUrl}
              srcset={mapAlbum(item.srcset)}
              data-sizes="auto"
              class="lazyload"
              alt
            />
          </div>
          <div class="small photographer">
            <div>By:</div>
            <div>{item.photographer ?? ""}</div>
          </div>
          <div class="small">Caption:{item.caption ?? ""}</div>
          <input type="checkbox" bind:checked={item.selected} />
        </button>
      {/each}
    </div>
  </div>
  {#if selectedPics.length > 0}
    <div class="options">
      Options
      <div>
        <button ord="primary" on:click={changePhotographer}
          >Change Photographer</button
        >
        <input
          type="text"
          placeholder="Photographer"
          bind:value={photographer}
        />
      </div>
      <div>
        <button ord="primary" on:click={changeCaption}>Change Caption</button>
        <input
          type="text"
          placeholder="Caption"
          bind:value={caption}
          size="80"
        />
      </div>
      <div>
        <button ord="primary" on:click={changeHidden}
          >{hidden ? "hide selected" : "show selected"}
          <m-icon name="ban" /></button
        >
      </div>
      <div>
        <button ord="primary" on:click={confirmDialog.showModal()}
          >{deleteAlbum ? "Delete Album" : "Delete Selected"}
          <m-icon name="trash" /></button
        >
      </div>
    </div>
  {/if}

  <dialog bind:this={confirmDialog}>
    <form method="dialog">
      <h3>Confirm deletion {deleteAlbum ? "of" : "from"} {album?.title}</h3>
      <p>
        {deleteAlbum
          ? `You have selected all the pictures (${noSelected}) in the album. 
      Deleting them will also result in deleting the album itself.`
          : `About to permanently delete ${noSelected} pictures from this album.`}
      </p>
      <p>This can't be undone</p>

      <p>Proceed?</p>
      <div>
        <button value="cancel" ord="secondary">No - keep them</button>
        <button
          id="confirmBtn"
          value="default"
          on:click={deleteAlbum ? deleteTheAlbum : deleteSelected}
          ord="primary">Yes - delete them</button
        >
      </div>
    </form>
  </dialog>
</div>

<script>
  import { onMount } from "svelte";
  import {
    getGalleryDataF,
    getGalleryDataURL,
    expandSrcset,
    doGalleryChanges,
  } from "@components/galleryMixin.mjs";
  import { pop, replace } from "svelte-spa-router";

  const mapAlbum = (src) => {
    // process.env.NODE_ENV !== 'production'
    const loc = "https://gallery.stedwardsfellwalkers.co.uk/albums";
    const ret = src.replace(/albums/g, loc);
    return ret;
  };

  export let params;
  let changed;
  let photographer = "";
  let caption = "";
  let album = {};
  let pictures = [];
  let selectedPics = [];
  let noSelected = 0;
  let confirmDialog;
  let renameAlbumDialog;

  const str = "🗹";
  $: albumId = params.albumId;
  // $: console.warn("album:", album);
  // $: console.warn("pictures:", pictures);
  $: selectedPics = pictures.filter((p) => p.selected).map((p) => p.pid);
  $: noSelected = selectedPics.length;
  $: deleteAlbum = noSelected === pictures.length;

  $: noSelectedHidden = pictures.filter((p) => p.selected && p.hidden).length;
  $: hidden = noSelected !== noSelectedHidden;
  $: console.warn("noSelected:", { noSelected, selectedPics, changed, hidden });
  $: if (changed) getPictures(albumId);
  onMount(async () => {
    await getPictures(albumId);
    console.log("getAlbums pictures", album, pictures);
  });

  async function getPictures(albumId) {
    album = await getGalleryDataF("getAlbumAll", albumId);
    album.title = album.title.replace(/&amp;/gi, "&");
    const directory = album.directory;
    console.log("getAlbums data", album, directory);
    pictures = album.pictures.map((pic) => {
      const imageUrl = getGalleryDataURL(directory, pic.filename);
      const srcset = expandSrcset(pic.srcset);
      return { ...pic, imageUrl, srcset };
    });
    changed = false;
  }

  const pictureClicked = (i) => {
    const setFields = (j) => {
      photographer = pictures[j].photographer;
      caption = pictures[j].caption;
    };
    const selected = pictures[i].selected;
    pictures[i].selected = !selected;
    if (noSelected === 0 && !selected) setFields(i);
    if (noSelected === 2 && selected) {
      const j = pictures.findIndex((p) => p.selected);
      setFields(j);
    }

    // console.log("pictureClicked", pictures[i].pid, selected, noSelected);
  };
  function updatePictures(update) {
    pictures = pictures.map((p) =>
      selectedPics.includes(p.pid) ? { ...p, ...update } : p,
    );
  }
  async function changePhotographer() {
    await doGalleryChanges("changePhotographer", {
      ids: selectedPics,
      photographer,
    });
    updatePictures({ photographer });
  }
  async function changeCaption() {
    await doGalleryChanges("changeCaption", {
      ids: selectedPics,
      caption,
    });
    updatePictures({ caption });
  }
  async function changeHidden() {
    await doGalleryChanges("changeHidden", {
      ids: selectedPics,
      hidden,
    });
    updatePictures({ hidden });
  }

  async function deleteSelected() {
    await doGalleryChanges("deletePictures", {
      ids: selectedPics,
      aid: albumId,
    });
    pictures = pictures.filter((p) => !p.selected);
  }
  async function deleteTheAlbum() {
    await doGalleryChanges("deleteAlbum", album);
    pictures = pictures.filter((p) => !p.selected);
  }
  function selectAll() {
    pictures = pictures.map((pic) => ({ ...pic, selected: true }));
  }
  function clearAll() {
    pictures = pictures.map((pic) => ({ ...pic, selected: false }));
  }
</script>

<style css="postcss">
  :root {
    --fontSize: 1em;
  }

  @media (max-width: 500px) {
    :root {
      --fontSize: 0.7em;
    }
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
  }

  .gallery .wrapper {
    box-sizing: border-box;
    padding: 2px 1px;
    min-width: 120px;
    max-width: 140px;
    border: thin solid #dcdcdc;
  }
  .title {
    display: flex;
    flex-direction: row;
  }
  /* .select {
    padding-left: 5px;
  }
  .select button {
    display: block;
  } */
  .buttons {
    display: flex;
    flex-direction: row;
  }
  .pic {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
  }
  .pic m-icon {
    display: block;
    font-size: 80px;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: bold;
    color: red;
    opacity: 0.7;
  }
  .pic img {
    display: block;
    height: 100px;
    width: auto;
    border: 0px;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
  }
  .blur {
    opacity: 0.5;
  }
  .gallery0 {
    /* display: flex;
    flex-direction: row; */
    box-sizing: border-box;
    padding-left: 0.3em;
  }
  .options > div {
    padding-top: 5px;
  }
  .photographer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-right: 10px; */
  }
  .small {
    width: 100%;
    font-size: 0.7em;
  }
  .pictures {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .brownBox {
    width: fit-content;
    max-width: none;
    min-width: 5em;
    font-size: var(--fontSize);

    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    border-width: 3px;
    border-radius: 0.56em;
    border-style: double;
    border-color: #444;
    padding: 0.2em 1.2em 0.2em 0.7em;
    margin: 0.2em 0 0 0;
    padding-bottom: 5px;
    text-decoration: none;
    color: #444;
    background-color: rgba(241, 222, 174, 0.98);
    margin-bottom: 5px;
    display: block;
  }
</style>
