<div>
  <p>
    If you are interested in join us then a take a look at the following items
  </p>
  <ul>
    <li>
      <a
        href="/apiServer/downloads/St Edwards Fellwalkers - Information for New Members & Guests 22.06.24.pdf"
        target="_blank">Information for New Member and Guests</a
      > to get answers to your questions
    </li>
    <li>
      <a href="/apiServer/downloads/StEdsBusRouteJune2024.pdf" target="_blank"
        >Bus Route</a
      > shows the options for joining the bus on a walk day
    </li>
    <li>
      <a use:link href={curProg}>Walks {year}</a>is the current walks programme
    </li>
    <li>
      <a use:link href="/About_the_Club/membership">Membership</a> shows the current
      fees.
    </li>
  </ul>
  <p>
    If there is something you still want to ask then just contact our membership
    secretary whose details are in the information leaflet and on the
    <a use:link href="/other/contact_us">contacts page</a>.
  </p>
  <p>
    If you are not sure then why not come out as a guest before committing to
    become a member.
  </p>
  <p>
    To join the club you'll need to fill in an
    <a href="/apiServer/downloads/StEdsNewMemberForm.pdf" target="_blank"
      >application form</a
    >
    and sent it to our membership secretary
  </p>
</div>

<script>
  import { link } from "svelte-spa-router";

  let year = new Date().getFullYear();
  let curProg = `/programme/year/${year}`;
</script>
