<div id="upload">
  {#if $isUploader}
    <div class="container">
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
      >
        <h1>Upload images</h1>
        <div class="album">
          <span class="prepend">Album</span>
          <input bind:value={album.title} disabled={album.aid !== -1} />
          <select
            required
            bind:value={albumSelected}
            on:change={() => (album = { ...albumSelected })}
          >
            <option value="" disabled selected>Choose an Album</option>
            {#each albums as alb}
              <option value={alb} style="text-align:left">{alb.title}</option>
            {/each}
          </select>
        </div>
        <div class="album">
          <span class="prepend">Photographer</span>
          <input bind:value={photographer} style="width:20em" />
          {#if album?.aid && album?.aid !== -1}
            <div>
              <a
                href={`/#/gallery/album/${album.aid}`}
                role="button"
                ord="secondary">View Album</a
              >
              <a
                href={`/#/hk/gallery/maintAlbum/${album.aid}`}
                role="button"
                ord="secondary">Maint Album</a
              >
            </div>
          {/if}
        </div>
        {#if album?.title && albumTitleOk}
          <Uploader bind:album {photographer} />
        {/if}
      </form>
    </div>
  {:else}
    <div>
      The uploading of photographs to the website is restricted to authorised
      members who must log in via the button in the page footer.
    </div>
    <div>
      Authorization for this function can be requested via the chairman.
    </div>
  {/if}
</div>

<script>
  import { onMount } from "svelte";
  import { getWalkData } from "$lib/WalksMixin";
  import { getGalleryDataF } from "@components/GalleryMixin.mjs";
  import Uploader from "./Uploader.svelte";
  // import FilePond from "./FilePond.svelte";
  import { isUploader } from "../../store/authentication";
  import { parseISO } from "date-fns";
  import _ from "lodash";

  const STATUS_INITIAL = 0;
  const STATUS_SAVING = 1;
  const STATUS_SUCCESS = 2;
  const STATUS_FAILED = 3;

  let albums = [];
  // biome-ignore lint/style/useConst: needs variable to be altered via html
  let albumSelected = "";
  // biome-ignore lint/style/useConst: needs variable to be altered via html
  let album = {};
  let photographer = null;
  const url = "./apiServer/cpg/upload";

  let state;
  let roles;
  let name;
  console.log(parseISO("2022-99-99"));
  let albumTitleOk;
  $: albumTitleOk =
    album.title &&
    parseISO(album?.title?.substr(0, 10)).toString() !== "Invalid Date";
  $: console.log("authenticated", albumTitleOk);
  $: if (album.id !== -1) {
    const a = albums.findIndex((a) => a.title === album.title);
    if (a !== -1 && albums[a].aid === -1) {
      albums[a].aid = album.aid;
      albums = [...albums];
    }
  }

  onMount(async () => {
    const year = new Date().getFullYear();
    const ret = await getWalkData("GetPastWalks");
    let walks = await ret.json();
    const dummy = { title: `${year}-99-99 create an album`, aid: -1 };
    walks = walks.map((w) => ({
      aid: -1,
      title: `${w.date} ${w.area}`,
    }));
    const res = await getGalleryDataF("getLatestAlbums");
    albums = res.filter((album) => /^\d+/.test(album.title));
    albums = _.uniqBy([dummy, ...albums, ...walks], "title");
    albums = _.orderBy(albums, "title", "desc");
    console.log("albums", albums);
    // album = albums[0];
    photographer = name;
  });
</script>

<style lang="postcss">
  select:required:invalid {
    color: #666;
  }
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: #000;
  }
  #upload .prepend {
    display: inline-block;
    color: #97a8be;
    height: 36px;
    font-size: 14px;
    padding: 9px 10px 0 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #97a8be;
    border-right-width: 0;
  }
  .album {
    display: grid;
    grid-template-columns: 110px 1fr 1fr;
  }
</style>
