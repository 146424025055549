<div>
  <p>
    Given that walking is what we are about, this section tells you what you'll
    want to know about how the club is run, starting with how the
    <a href="/#/Walk_Organization/planning">walk programme is created</a>,
    through
    <a href="/#/Walk_Organization/bus_logistics"
      >what happens on the day of a walk</a
    >.
  </p>
  <p>
    To book a place on a walk see the <a href="/#/About_the_Club/bookings"
      >bookings</a
    > page.
  </p>
  <hr />
  <p>The walk programme is available <a href={curProg}>here</a>.</p>
  <hr />
  <p>
    When on a walk, accidents can sometimes happen and the weather can change
    for the worse unexpectedly. The club has spent a lot of effort planning for
    how we deal with these issues. Safety guidelines and procedures for handling
    unplanned events can be found under
    <b>Guidelines</b>
  </p>
</div>

<script>
  let year = new Date().getFullYear();
  let curProg = `/#/programme/year?year=${year}`;
</script>
