<div>
  <p>
    The need for Club Guidelines arose from incidents on past walks. Members are
    urged to be familiar with these Guidelines and to strive to attain the
    recommendations contained within them.
  </p>
  <p>
    While the club takes every effort to ensure the safety of our members, it
    still remains that everybody participate entirely at their own risk. The
    bottom line is that every individual is responsible for their own safety. St
    Edward's Fellwalkers Club cannot be held responsible for any accident, or
    for any individual's shortcomings.
  </p>

  <hr />
  <p>
    These guidelines cover the spectrum from individuals being
    <a href="/#/guidelines/equipment/">properly equipped</a>, through the role
    of <a href="/#/guidelines/member_s_role/"> members</a> and
    <a href="/#/guidelines/leader_s_role/">leaders</a>
    on the day, through to how how we
    <a href="/#/guidelines/emergencyPlanning/">prepare</a>
    for and <a href="/#/guidelines/emergencyProcedures/"> deal</a> with emergencies
    when they occur.
  </p>

  <hr />
  <p>
    <em
      >If you are a member of another club and wish to use these guidelines in
      part or in whole then do so at your own risk. Conversely, if you see ways
      to improve them then please
      <a href="/#/guidelines/contact_us/">contact us</a>
      - we are always willing to learn and improve our procedures</em
    >
  </p>

  <hr />
</div>
