<div class="gallery">
  <div class="years">
    {#each yearList ?? [] as { year, count }}
      <button
        type="button"
        class="item brownBox"
        on:click={() => pickYear(year)}
        class:current={year === curYear}
        data-count={count}
      >
        {year}
      </button>
    {/each}
  </div>
  <div class="albums">
    {#each albumList ?? [] as album}
      <a
        class="item brownBox"
        href={`/gallery/album/${album.aid}`}
        class:current={album.aid === curAlbum}
        data-count={album.count}
        data-len={album.title.length}
        use:link
      >
        {album.title}
      </a>
    {/each}
  </div>
</div>

<script>
  import { onMount } from "svelte";
  import { link, push } from "svelte-spa-router";
  import {
    getGalleryDataF,
    getGalleryDataURL,
    expandSrcset,
  } from "@components/galleryMixin.mjs";
  import { isUploader } from "../../store/authentication";

  import Lightbox from "@components/gallery/Lightbox.svelte";
  // var Lightbox;
  export let params;

  let curYear;
  let yearList = [];
  let curAlbum;
  let albumList = [];
  let pictures;

  onMount(async () => {
    yearList = await getGalleryDataF("getYears");
    if (params?.year) await getAlbums(params.year);
  });
  const pickYear = (year) => {
    if (year !== curYear) push(`/gallery/albums/${year}`);
    else push("/gallery/albums");
  };

  const getAlbums = async (year) => {
    curYear = year;
    const data = await getGalleryDataF("getAlbumList", year);
    for (const alb of data) {
      alb.title = alb.title.replace("&amp;", "&");
    }

    albumList = data;
    return data;
  };
  const getAlbum = async (album) => {
    curAlbum = album;
    const data = await getGalleryDataF("getAlbum", album);
    const directory = data[0].directory;
    console.log("getAlbums data", data, directory);
    pictures = data[0].pictures.map((pic) => {
      const imageUrl = getGalleryDataURL(directory, pic.filename);
      const srcset = expandSrcset(pic.srcset);
      // pic.albumTitle = pic.album.title.replace(/&amp;/gi, "&");
      return { ...pic, imageUrl, srcset };
    });
    console.log("getAlbums pictures", album, pictures);
  };
  $: curYear = params?.year;
  $: getAlbums(curYear);
  // $: console.warn("yearList:", yearList);
  // $: console.warn("albumList:", albumList);
  // $: console.warn("curYear:", curYear);
  // $: console.warn("curAlbum:", curAlbum);
</script>

<style css="postcss">
  :root {
    --fontSize: 1em;
    --albumWidth: 12.5em;
    --liWidth: 20em;
  }

  @media (max-width: 500px) {
    :root {
      --fontSize: 0.7em;
      --albumWidth: 9em;
      --liWidth: calc(100vw-6em);
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .albums a {
    display: block;
    color: #444;
  }
  .years button {
    display: block;
    color: #444;
  }

  [data-count]:after {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 0.7em;
    color: white;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    text-indent: 0;
    line-height: 1.6em;
    border-radius: 50%;
    z-index: 100;
    overflow: visible;
    box-shadow: 0 0 1px #333;
    color: #888;
    background-color: rgba(241, 222, 174, 0.98);
    content: attr(data-count);
  }
  .current[data-count]:after {
    background-color: rgba(136, 91, 43, 0.94);
    color: #fff;
  }

  .item {
    list-style: none;
    overflow: visible;
    transition: all 0.2s ease-in-out;
    max-width: var(--liWidth);
    min-width: var(--liWidth);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .brownBox {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    border-style: solid;
    border-width: 3px;
    border-radius: 0.56em;
    border-color: #ccc;
    padding: 0.2em 1.2em 0.2em 0.7em;
    margin: 0.2em 0 0 0;
    font-size: var(--fontSize);
  }
  .brownBox.current {
    background-color: rgba(241, 222, 174, 0.98);
    border-style: double;
    border-color: #444;
  }
  .years button {
    min-width: 4em;
  }
  .item:hover {
    background-color: rgba(244, 237, 220, 0.98);
    width: fit-content;
    z-index: 200;
    max-width: none;
    overflow: visible;
    text-decoration: none;
    color: #444;
  }
</style>
