<div>
  <p>
    Unfortunately accidents do happen and so need to planned for in terms of
    equipment and procedures.
  </p>

  <h5>Club Equipment</h5>

  <ul class="simpleList">
    <li>
      The club has five KSU's (Karrimor Survival Units) and each walk will carry
      one for emergency use, as well as to familiarise members in their use.
    </li>

    <li>
      There are 5 sets of Club Cas-straps available and at least 12 other sets
      that are the personal property of members.
    </li>
  </ul>

  <h5>Composition of a Group:</h5>

  <ul class="simpleList">
    <li>
      For group control and safety on the fells, a <strong>maximum</strong> of 15
      walkers in a group is recommended - subject to the discretion of the group
      leader. The party may be split to achieve this recommendation, the main leader
      appointing a secondary leader and "sweeper".
    </li>

    <li>
      Again for safety on the fells a group <strong>minimum</strong> of 5 is recommended.
      Such small groups MUST include at least two persons who are competent with
      map and compass and the group is carrying the maps for the walk and compasses.
    </li>
  </ul>

  <h5>Member Information</h5>

  <ul class="simpleList">
    <li>
      All members will be asked to provide the Club with an emergency contact
      name and phone number.
    </li>

    <li>
      Members WILL carry their Membership Card with them on all walks. Members
      are requested to supplement the information given on their Membership Card
      by including:-

      <ul class="simpleList">
        <li>Date of Birth</li>

        <li>Ailments and/or allergies</li>

        <li>Disabilities</li>

        <li>Any long term medical treatment(s)</li>

        <li>Blood Group</li>

        <li>Home phone number</li>

        <li>Name and phone number of an emergency contact.</li>
      </ul>
      Possession of such a completed Membership Card will speed up any emergency
      treatment given by Members of the group or by a Fell Rescue Team in case your
      accident renders you unconscious, in addition to enabling speedy contact to
      be made with your emergency contact.
    </li>
  </ul>

  <h5>The Coach</h5>
  <p>
    The coach is always there at the end of the walk and so is the focal point
    when things go wrong
  </p>

  <ul class="simpleList">
    <li>
      An Alphabetical register of names and addresses of all Club Members WILL
      remain on the coach.
    </li>

    <li>
      Duplicate lists of the composition of each walking group on the day WILL
      remain on the coach.
    </li>

    <li>A copy of the route maps WILL remain on the coach.</li>

    <li>
      A list of the mobile phone numbers for members WILL remain on the coach.
    </li>
  </ul>
</div>
