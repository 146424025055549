<div id="walkData">
  <div id="walkDetail">
    <p class="area">{walk.area}</p>
    <p class="region">{walk?.region?.regname}</p>
    <!-- <p class="date">{{walk.dispDate}}</p> -->
    <p><span>Bus Departure:</span> {walk.time} a.m.</p>
    <p><span>Organizer:</span> {walk.organizer}</p>
    <p><span>Maps:</span> {walk.map}</p>
    {#if walk.coffee}
      <p><span>Morning Coffee:</span> {walk.coffee}</p>
    {/if}
    {#if walk.evening}
      <p v-if="walk.evening"><span>Evening Meal:</span> {walk.evening}</p>
    {/if}
    {#if walk.details === "Y"}
      <p class="screenonly">
        See
        <a href={getWalkdataURL(walk.img)} target="_blank">Route Map</a>
        for these walks.
      </p>
    {/if}
    {#if walk.scaledPrf === "Y"}
      <div id="instructions" class="screenonly">
        The profiles for these walks are all to the same scale.
        <br />The distances shown, under the profiles, are the distances
        remaining to the finish.
      </div>
    {:else}
      <div id="instructions" class="screenonly">
        The profiles for these walk are
        <b>not</b>
        to the same scale. (The blue vertical lines correspond with the waypoints
        on the route card.)
      </div>
    {/if}
  </div>

  <div id="walkDetails">
    {#each routes as route, no}
      <div class="walk">
        <div class="routeHeading">
          <div>
            <strong>Walk {route.no}</strong>
          </div>
          {#if route.leader}
            <div>
              <span>Leader:</span>
              {route.leader}
            </div>
          {/if}
        </div>
        <div class="profile">
          <img
            border="0"
            src={getWalkdataURL(route.prfImg)}
            alt
            class="profileImg"
            isize={route.imgSize}
          />
        </div>
        <div class="routeDetails">
          <span>Distance:</span>
          {showDist(route.distance)}
          Mi
          <span>Ascent:</span>
          {route.ascent}
          ft
          <span>Descent:</span>
          {route.descent}
          ft
        </div>
        <div class="screenonly routeDetails links">
          <a
            href={route.gpxFile}
            download
            target="_blank"
            title="Download route as gpx"
          >
            <img
              src="/assets/image/gpx.png"
              style="height:30px"
              alt="download gpx"
            />
          </a>
          <a href={showRoute(route.no)}>
            <img
              src="/assets/image/OS.png"
              style="height:30px"
              title="View route on OS map"
              alt="show route"
            />
          </a>
        </div>
      </div>
    {/each}
  </div>
</div>

<script>
  import { onMount } from "svelte";
  import {
    getWalkDataC,
    getWalkdataURL,
    getWalkProfileURL,
    getWalkURL,
  } from "$lib/WalksMixin";
  import { format } from "date-fns";
  export let params;
  let { walkId } = params;
  let walk = {};
  let routes = [];
  let gpxJ;

  const loadWalk = async (walkId) => {
    // let { params, query } = $route;
    // console.log("walkDetail beforeMount",  params, query);
    // let walkId = walkId || params.walkId || query.walkId;
    console.log("walkDetail beforeMount", walkId, walkId);
    // let res = await getWalkDataC("GetWalkDetails", walkId);
    // walk = await res.json();
    const data = await getWalkDataC("getWalkDetails", walkId);
    walk = data.details;
    // gpxJ = walk.gpx;
    routes = [...(data.routes || [])].sort((a, b) => a.no - b.no);
    console.log("walkData", walk, routes);
  };
  $: loadWalk(walkId);
  const dispDate = (dat) => format(new Date(dat), "dddd, d MMMM yyyy ");

  const fract = ["", "¼", "½", "¾"];
  const showRoute = (no) =>
    `/#/programme/OSMap/${walk.date}/${no}/${routes.length}`;
  const showDist = (dist) => {
    let d = Math.round(dist * 4);
    return `${Math.floor(d / 4)}${fract[d % 4]}`;
  };
  $: scaleImage = 655 / ((routes[0] || {}).imgWd || 1);
</script>

<style lang="postcss">
  #instructions {
    background-color: #ccffcc;
    margin: 2%;
    padding: 2%;
    color: #0000cc;
    border: thin solid #ffcc66;
  }
  #walkData {
    padding: 5px;
  }

  #walkDetail {
    text-align: left;

    & p {
      padding: 0;
      margin: 0;
      font-weight: bold;

      &.area {
        color: #333333;
        font-size: xx-large;
      }
      & span {
        display: inline;
        color: #999999;
      }
    }
  }
  .walk {
    margin: 3% 0 0 0;
    border: 2px solid #cc9999;
    overflow: visible;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .walk .profile {
    padding-right: 3px;
    align-self: flex-end;
  }
  .walk .routeDetails {
    font-size: 0.8em;
    text-align: center;
  }
  .walk .routeHeading {
    text-align: center;
    padding-right: 0;
    border-bottom: 2px solid #cc9999;
    background: MistyRose;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .routeDetails.links img {
    height: 30px;
    padding: 0 1em;
  }
</style>
