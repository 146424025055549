<div id="programme" v-if="walksDetails.length > 0">
  <div class="yearprog">
    {#each walksDetails as walk}
      <button
        type="button"
        class:detailN={walk.details !== "Y"}
        class="detail"
        on:click={setHiWalk(walk)}
        title={toolTip(walk)}
      >
        <span class="date">{dispDat(walk.date)}</span>
        <span class="detail">{walk.area}</span>
      </button>
    {/each}
  </div>
  <div id="hiWalk" class="programme">
    <WalkHighlight walk={hiWalk} />
  </div>

  <div v-show="progPDF" class="screenonly progPDF">
    <a href={progPDF}>
      <img src="/assets/image/PDF_file_icon.svg" alt="pdf logo"/>
     <span> Download the {year} programme as a PDF document</span></a>
  </div>
</div>

<script>
  import { onMount, beforeUpdate, afterUpdate } from "svelte";
  import { format } from "date-fns";
  import { getWalkData } from "$lib/WalksMixin.js";
  import WalkHighlight from "./_WalkHighlight.svelte";

  export let params;
  $: year = params.year;

  console.log("Years prog", year);
  let walksDetails = [];
  let hiWalk = {};
  let progPDF;
  const yearsWalks = async (year) => {
    const response = await getWalkData("getYearsData", year);
    const data = await response.json();
    console.log(data);
    const dat = format(new Date(), "yyyy-MM-dd");
    console.log({ dat });
    walksDetails = data?.walksDetails ?? [];
    hiWalk = data?.hiWalk;
    progPDF = data?.progPDF;
    return data;
  };
  const dispDat = (dat) => {
    return format(new Date(dat), "MMM d");
  };
  const toolTip = (walk) => {
    return `${walk.regname}, bus: ${walk.time}, org: ${walk.organizer}, maps: ${walk.map}`;
  };
  const setHiWalk = async (walk) => {
    console.log("showing", walk);
    const response = await getWalkData("getWalkData", walk.date);
    hiWalk = await response.json();
  };
  $: yearsWalks(year);
  // onMount(() => {
  //   yearsWalks(year);
  // });
  beforeUpdate(() => {
    console.log("BeforeUpadte;", $$props);
    // yearsWalks(year);
  });
  afterUpdate(() => {
    console.log("BeforeUpadte;", $$props);
    // yearsWalks(year);
  });
</script>

<style scoped>
  #programme {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .yearprog {
    border: 3px solid #cc9999;
    padding: 6px;
    width: 400px;
    max-width: 100%;

    columns: 150px 2;
    column-rule: 3px solid #cc9999;
    font-size: x-small;
  }
  .progPDF {
    align-self: center;
    padding: 0.5em 1em;
    margin: 1em;
    border: black 3px solid;
    border-radius: 1em;
    background-color: rgb(235, 206, 208);
    &:hover{
       outline: red 2px solid;


     }
    & a{
      display:flex;
      flex-direction: row;
      align-content: center;
      position: relative;
      height: 3em;
      text-decoration: none;
      &:hover{
        background-color: inherit;
        color: inherit;
      }
      & img{
        display:block;
        height: 3em;
      }
      & span{
        display: block;
        max-height: 2em;
        position: relative;
        padding-left:2em;
        top: 0.8em;
        color:black;
      }
    }
  }
  .yearprog span {
    display: inline-block;
  }
  .yearprog .date {
    display: inline-block;
    width: 46px;
    padding-left: 3px;
  }
  .detail {
    display: block;
    background-color: unset;
    border-width: 0;
    color: #cc3300;
  }
  .detail:hover {
    color: white;
    background-color: #cc3300;
  }
  .detailN .detail {
    color: tan;
  }

  #hiWalk {
    padding: 6px;
    border: 3px solid #cc9999;
    border-top-width: 0;
    width: 320px;
  }

  @media only screen and (max-width: 338px) {
    .yearprog {
      width: auto;
    }
    #hiWalk {
      width: auto;
    }
  }
</style>
