<div class="relative">
  <input
    type="text"
    on:focus={onFocus}
    bind:value={dispSelected}
    on:change={onChanged}
  />
  {#if selected}
    <button on:click={() => (selected = null)} class="clear">X</button>
  {/if}
  {#if showDatePicker}
    <div class="box">
      <button on:click={() => (showDatePicker = false)} class="close">X</button>
      <div class="month-name">
        <div class="center">
          <button on:click={prev}>Prev</button>
        </div>
        <div class="center">{format(dispMonth, "MMMM yyyy")}</div>
        <div class="center">
          <button on:click={next}>Next</button>
        </div>
      </div>
      <Calender {dispMonth} bind:selected {isAllowed} bind:showDatePicker />
    </div>
  {/if}
</div>

<script>
  import Calender from "./Calender.svelte";
  import {
    format,
    parseISO,
    addMonths,
    subMonths,
    startOfMonth,
  } from "date-fns";
  const log = (...rest) =>
    echo.log(echo.white.bgBlue.bold("DatePicker"), ...rest);
  // props
  export let isAllowed = () => true;
  export let selected;
  export let dispNil = "";

  $: if (selected?.length === 0) selected = null;
  $: dateSelected = /[0-9-]+/.test(selected);
  $: baseDate = dateSelected ? selected : format(new Date(), "yyyy-MM-dd");
  $: dispMonth = startOfMonth(parseISO(baseDate));
  $: dispSelected = selected
    ? format(parseISO(selected), "eee, do MMM, yyyy ")
    : dispNil;
  $: log("selected", selected, dateSelected, dispSelected);
  // state
  let date, month, year, showDatePicker;

  // handlers
  const onFocus = () => {
    showDatePicker = true;
  };
  import { createEventDispatcher } from "svelte";

  const next = () => {
    dispMonth = addMonths(dispMonth, 1);
    log("next", dispMonth);
  };
  const prev = () => {
    dispMonth = subMonths(dispMonth, 1);
    log("prev", dispMonth);
  };
  const onChanged = (e) => {
    log("changed", e, { value: e.target.value });
    if (e.target.value.length === 0) selected = null;
  };
</script>

<style>
  .relative {
    position: relative;
  }
  .relative input {
    margin: 0;
    padding: 0;
    /* padding-bottom: 5px; */
  }
  .relative:not(:focus-within) .box {
    display: none;
  }
  .box {
    position: absolute;
    top: 40px;
    left: 0px;
    border: 1px solid green;
    display: inline-block;
    z-index: 10;
    background-color: white;
  }
  .clear {
    font-size: 0.5em;
    background-color: #888;
    color: white;
    right: 0.5em;
    top: 20%;
    bottom: 0;
  }
  .close {
    background-color: red;
    color: white;
    right: -0.75em;
    top: -0.75em;
  }
  .close,
  .clear {
    position: absolute;
    border: 1px solid black;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    font-weight: bold;
    padding: 0;
    /* z-index: 15; */
  }

  .month-name {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 6px 0;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
