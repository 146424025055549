// import axios from "axios";
import { writable, derived } from "svelte/store";
import { forEach, mapValues, keys, intersection, isArray, merge } from "lodash";
import { v4 as uuidv4 } from "uuid";

const stedsServer = process.env.GALLERY_SERVER;

export const authState = writable({
  ip: null,
  state: -1,
  mId: null,
  identifier: null,
  authseq: 0,
  limit: null,
  error: null,
  via: null,
  name: null,
  roles: "",
});

let visitorId;
let userName;
let memId;
let authseq;
export const getAuthUser = () => ({ visitorId, userName, memId, authseq });
export const getMemId = () => memId;
export const getAuthseq = () => authseq;
async function authFetch(url) {
  if (!visitorId) visitorId = await getVisitorId();
  const res = await fetch(`${stedsServer}/auth/${url}`, {
    headers: {
      "Auth-Token": visitorId,
      "user-name": userName,
      "auth-seq": authseq,
      "member-id": memId,
      userName,
      authseq,
      memId,
    },
  });
  // console.log("authFetch returned", res);
  return await res.json();
}

export const getVisitorId = async () => {
  let visitorId = localStorage.getItem("fingerPrint");
  if (!visitorId) {
    visitorId = uuidv4();
    localStorage.setItem("fingerPrint", visitorId);
  }
  // Get the visitor identifier when you need it.
  console.log("visitorId", visitorId);
  return visitorId;
};

export const isUploader = derived(
  authState,
  ($authState) =>
    $authState.state === 2 && $authState.roles.includes("uploader"),
);
export const isAdmin = derived(
  authState,
  ($authState) => $authState.state === 2 && $authState.roles.includes("admin"),
);
export const isOkAs = (role) => {};
export async function getAuth(roleReq) {
  const data = await authFetch("getAuth");
  const roles = (data.roles || "").split(",");
  const want = [...(isArray(roleReq) ? roleReq : [roleReq]), "admin"];
  const inter = intersection(roles, want);
  console.log("authtest data", data, roleReq, roles, want, inter);
  const gotRole = intersection(roles, want).length > 0;
  if (!gotRole && data.state > 0) {
    data.error = `You don't have permission for this request`;
  }
  newAuthentication(data);
}
export async function checkAuth() {
  const { data } = await authFetch("checkAuth");

  console.log("authtest data", data);
  newAuthentication(data);
}
export function requestSignin() {
  authState.update((AS) => ({
    ...AS,
    state: AS.state < 0 ? 0 : AS.state,
  }));
}
export async function sendRequest(payload) {
  console.log("sendRequest", payload);
  const data = await authFetch(`${payload.join("/")}`);
  console.log("sendRequest reponse data", data);
  newAuthentication(data);
}

function newAuthentication(value) {
  userName = value.name;
  authseq = value.authseq;
  memId = value.memid;
  authState.update((state) => {
    console.log("oldAuthentication", state);

    mapValues(state, () => null);
    merge(state, value ?? {});
    console.log("newAuthentication", state);
    return state;
    // Window.localStorage.setItem('authentication', JSON.stringify(value));
  });
}
