<div>
  <p>Membership is open to anyone over 14 years of age.</p>
  <p><strong>Subscription:</strong> Annual subscriptions are:</p>
  <table class="data">
    <tr>
      <th align="left">New Members/Lapsed Members</th> <td>&pound;{memFee}</td>
    </tr> <tr> <th align="left">Renewals</th> <td>&pound;{memRenew}</td> </tr>
  </table>
  <p>
    If you are interested in joining us then contact the membership secretary
    (see "Contact Us" for details). You can come out on a walk as a guest, just
    paying the standard walk fee of &pound;{walkFee} before committing yourself to
    joining.
  </p>
</div>

<script>
  import { walkFee, memFee, memRenew } from "@store/fees.js";
</script>
