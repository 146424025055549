<div>
    <p><a target="_blank" href="http://www.countrysideaccess.gov.uk/things_to_know/countryside_code">Countryside      code</a></p>
    <p><a href="http://www.northtyneside.gov.uk/walks.htm" target="_blank" title="North Tyneside Council - Walks and Trails">North Tyneside Council - Walks and Trails</a></p>
    <p><a href="http://www.walkingenglishman.com/" target="_blank" title="The Walking Englishman">The Walking Englishman</a></p>
    <p><a href="http://www.visitnorthumberland.com/" target="_blank" title="Visit Northumbria">Visit Northumberland</a></p>
    <p><a href="http://www.visitnortheastengland.com/" target="_blank" title="Visit Northumbria">Visit North East England</a></p>
    <p><a href="http://www.ramblers.org.uk/" target="_blank" title="The Ramblers">The Ramblers</a></p>
    <p><a href="http://www.walkingbritain.co.uk/" target="_blank" title="Walking Britain">Walking Britain</a></p>
    <p><a href="http://www.walkingeurope.info/" target="_blank" title="Walking Europe">Walking Europe</a></p>
    <p><a href="http://www.mwis.org.uk/" target="_blank" title="Mountain Weather Information Service">Mountain Weather Information Service</a></p>
    <p><a href="http://thebmc.co.uk/" target="_blank" title="British Mountaineering Council">British Mountaineering Council</a></p>
    <p><a href="http://www.mountain-training.org/award-schemes/walking-group-leader-award" target="_blank"    title="The Walking Group Leader Award">The Walking Group Leader Award</a></p>
    <p><a href="http://www.walkoutdoors.com/" target="_blank" title="Walk Outdoors - Equipment Supplier">Walk Outdoor - Equipment Supplier</a></p>

  </div>
