import axios from "axios";
import { camelCase, trimEnd } from "lodash";
// import { mapState } from 'vuex';
// import { stedsServer } from "~/assets/js/config";
export const stedsServer = process.env.API_SERVER;
// export const walkdata = "https://walkdata.stedwardsfellwalkers.co.uk";
// const stedsServer =
//   process.env.NODE_ENV === "production"
//     ? "http://server.stedwardsfellwalkers.co.uk"
//     : "http://steds-server.test";

const cache = new Map();
export const getWalkURL = (func, ...args) => {
  const url = (args ?? []).reduce(
    (res, arg) => `${res}/${arg}`,
    camelCase(func)
  );
  console.log("getWalkURL", func, args, url);
  // const url = `/${camelCase(func)}"/" ${args.join("/")}`;

  return `${stedsServer}/walks/${url}`;
};

export const getWalkProfileURL = (url) => `${stedsServer}${url}`;

export const getWalkdataURL = (url) => `${stedsServer}/${url}`;

// export const stedsServer = "https://server.stedwardsfellwalkers.co.uk";
// export const walkdata = "https://walkdata.stedwardsfellwalkers.co.uk";
// const stedsServer =
//   process.env.NODE_ENV === "production"
//     ? "http://server.stedwardsfellwalkers.co.uk"
//     : "http://steds-server.test";

export const getWalkDataC = (...args) => {
  const url = getWalkURL(...args);

  // if (cache.has(url)) {
  //   // console.log("WalksMixin getWalkDatf from cache", url, cache.get(url));
  //   return Promise.resolve(cache.get(url));
  // }

  const load = async () => {
    console.log("WalksMixin getWalkDataC fetching", url);
    const response = await fetch(url);
    const data = await response.json();
    // console.log("WalksMixin getWalkDatf fetched", url, data);
    cache.set(url, data);
    return Promise.resolve(data);
  };
  return load();
  // return store;
};
export const getWalkData = (...args) => {
  // console.log('walksMixin', process.env.NODE_ENV);
  const url = getWalkURL(...args);
  // const url = args.reduce((res, arg) => `${res}/${arg}`, '');

  return fetch(url);
};

export const postWalkData = function (data, ...args) {
  console.log("walkmixins postWalkData", this);
  const url = getWalkURL(...args);
  // const url = args.reduce((res, arg) => `${res}/${arg}`, '');
  return axios
    .post(url, {
      auth: {
        id: this.mId,
        authSeq: this.seq,
      },
      ...data,
    })
    .then((res) => res.walkdata)
    .then((data) => {
      if (data.error) {
        this.$notify({
          type: "error",
          title: args.join(),
          message: data.error,
        });
        throw data.error;
      }
    });
};
// export const getWalkURL = function (...args) {
//   const url = args.reduce((res, arg) => `${res}/${arg}`, "");

//   return `${stedsServer}/walks${url}`;
// };

// export const getWalkProfileURL = function (...args) {
//   let url = args.reduce((res, arg) => `${res}/${arg}`, "");
//   if (url.substr(0, 2) === "//") url = url.substr(1);
//   if (url.substr(0, 9) === "/walkdata") url = url.substr(9);
//   return `${walkdata}${url}`;
//   // return `${stedsServer}/walkdata${url}`;
// };

// export const getWalkdataURL = function (...args) {
//   let url = args.reduce((res, arg) => `${res}/${arg}`, "");
//   if (url.substr(0, 2) === "//") url = url.substr(1);
//   if (url.substr(0, 9) === "/walkdata") url = url.substr(9);
//   // return `http://www.stedwardsfellwalkers.co.uk/walkdata${url}`;
//   return `${walkdata}${url}`;
// };

// export default {
//   computed: {
//     ...mapState("authentication", ["mId", "identifier", "authSeq"]),
//   },
//   methods: {
//     getWalkData,
//     getWalkURL,
//     getWalkdataURL,
//     getWalkProfileURL,
//     postWalkData,
//     mToFt(m) {
//       return Math.round(Math.round(m * 3.28084) / 50) * 50;
//     },
//     dTo1(d) {
//       return Number.parseInt(d).toFixed(1);
//     },
//   },
// };
