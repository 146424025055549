<ul class="simpleList">
  <li>Bookings are taken for up to three walks into the future.</li>

  <li>
    Bookings can be made as follows
    <ul>
      <li>
        Online via WebCollect <a
          href="https://webcollect.org.uk/sec/index.php?page=login&action=login.sign_in_or_sign_up&organisation_id=3735"
          target="_blank">https://webcollect.org.uk/stedwardsfellwalkers</a
        >.
      </li>
      <li>
        Prior to the Wednesday Committee meetings at 7.00 p.m. to 7.30 p.m.
      </li>
      <li>
        By email: <a href="mailto:bookings@stedwardsfellwalkers.co.uk"
          >bookings@stedwardsfellwalkers.co.uk</a
        >
      </li>
    </ul>
  </li>
  <li>
    If you are unable to walk, please advise the Booking Secretary. This will
    enable another member to take your place.
  </li>
  <li>
    <strong>CANCELLATIONS:</strong> Cancelled bookings up to 6:00 PM on the Sunday
    evening before the walk will result in a credit against future walks. There will
    be no credits or money refunded after this time.
  </li>
</ul>
