// The list of colors taken from MDN
// --
// https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
echo.defineStyler("black", "color: #000000");
echo.defineStyler("bgBlack", "background-color: #000000");

echo.defineStyler("silver", "color: #c0c0c0");
echo.defineStyler("bgSilver", "background-color: #c0c0c0");

echo.defineStyler("gray", "color: #808080");
echo.defineStyler("bgGray", "background-color: #808080");

echo.defineStyler("white", "color: #ffffff");
echo.defineStyler("bgWhite", "background-color: #ffffff");

echo.defineStyler("maroon", "color: #800000");
echo.defineStyler("bgMaroon", "background-color: #800000");

echo.defineStyler("red", "color: #ff0000");
echo.defineStyler("bgRed", "background-color: #ff0000");

echo.defineStyler("purple", "color: #800080");
echo.defineStyler("bgPurple", "background-color: #800080");

echo.defineStyler("fuchsia", "color: #ff00ff");
echo.defineStyler("bgFuchsia", "background-color: #ff00ff");

echo.defineStyler("green", "color: #008000");
echo.defineStyler("bgGreen", "background-color: #008000");

echo.defineStyler("lime", "color: #00ff00");
echo.defineStyler("bgLime", "background-color: #00ff00");

echo.defineStyler("olive", "color: #808000");
echo.defineStyler("bgOlive", "background-color: #808000");

echo.defineStyler("yellow", "color: #ffff00");
echo.defineStyler("bgYellow", "background-color: #ffff00");

echo.defineStyler("navy", "color: #000080");
echo.defineStyler("bgNavy", "background-color: #000080");

echo.defineStyler("blue", "color: #0000ff");
echo.defineStyler("bgBlue", "background-color: #0000ff");

echo.defineStyler("teal", "color: #008080");
echo.defineStyler("bgTeal", "background-color: #008080");

echo.defineStyler("aqua", "color: #00ffff");
echo.defineStyler("bgAqua", "background-color: #00ffff");

echo.defineStyler("orange", "color: #ffa500");
echo.defineStyler("bgOrange", "background-color: #ffa500");

echo.defineStyler("aliceblue", "color: #f0f8ff");
echo.defineStyler("bgAliceblue", "background-color: #f0f8ff");

echo.defineStyler("antiquewhite", "color: #faebd7");
echo.defineStyler("bgAntiquewhite", "background-color: #faebd7");

echo.defineStyler("aquamarine", "color: #7fffd4");
echo.defineStyler("bgAquamarine", "background-color: #7fffd4");

echo.defineStyler("azure", "color: #f0ffff");
echo.defineStyler("bgAzure", "background-color: #f0ffff");

echo.defineStyler("beige", "color: #f5f5dc");
echo.defineStyler("bgBeige", "background-color: #f5f5dc");

echo.defineStyler("bisque", "color: #ffe4c4");
echo.defineStyler("bgBisque", "background-color: #ffe4c4");

echo.defineStyler("blanchedalmond", "color: #ffebcd");
echo.defineStyler("bgBlanchedalmond", "background-color: #ffebcd");

echo.defineStyler("blueviolet", "color: #8a2be2");
echo.defineStyler("bgBlueviolet", "background-color: #8a2be2");

echo.defineStyler("brown", "color: #a52a2a");
echo.defineStyler("bgBrown", "background-color: #a52a2a");

echo.defineStyler("burlywood", "color: #deb887");
echo.defineStyler("bgBurlywood", "background-color: #deb887");

echo.defineStyler("cadetblue", "color: #5f9ea0");
echo.defineStyler("bgCadetblue", "background-color: #5f9ea0");

echo.defineStyler("chartreuse", "color: #7fff00");
echo.defineStyler("bgChartreuse", "background-color: #7fff00");

echo.defineStyler("chocolate", "color: #d2691e");
echo.defineStyler("bgChocolate", "background-color: #d2691e");

echo.defineStyler("coral", "color: #ff7f50");
echo.defineStyler("bgCoral", "background-color: #ff7f50");

echo.defineStyler("cornflowerblue", "color: #6495ed");
echo.defineStyler("bgCornflowerblue", "background-color: #6495ed");

echo.defineStyler("cornsilk", "color: #fff8dc");
echo.defineStyler("bgCornsilk", "background-color: #fff8dc");

echo.defineStyler("crimson", "color: #dc143c");
echo.defineStyler("bgCrimson", "background-color: #dc143c");

echo.defineStyler("cyan", "color: #00ffff");
echo.defineStyler("bgCyan", "background-color: #00ffff");

echo.defineStyler("darkblue", "color: #00008b");
echo.defineStyler("bgDarkblue", "background-color: #00008b");

echo.defineStyler("darkcyan", "color: #008b8b");
echo.defineStyler("bgDarkcyan", "background-color: #008b8b");

echo.defineStyler("darkgoldenrod", "color: #b8860b");
echo.defineStyler("bgDarkgoldenrod", "background-color: #b8860b");

echo.defineStyler("darkgray", "color: #a9a9a9");
echo.defineStyler("bgDarkgray", "background-color: #a9a9a9");

echo.defineStyler("darkgreen", "color: #006400");
echo.defineStyler("bgDarkgreen", "background-color: #006400");

echo.defineStyler("darkgrey", "color: #a9a9a9");
echo.defineStyler("bgDarkgrey", "background-color: #a9a9a9");

echo.defineStyler("darkkhaki", "color: #bdb76b");
echo.defineStyler("bgDarkkhaki", "background-color: #bdb76b");

echo.defineStyler("darkmagenta", "color: #8b008b");
echo.defineStyler("bgDarkmagenta", "background-color: #8b008b");

echo.defineStyler("darkolivegreen", "color: #556b2f");
echo.defineStyler("bgDarkolivegreen", "background-color: #556b2f");

echo.defineStyler("darkorange", "color: #ff8c00");
echo.defineStyler("bgDarkorange", "background-color: #ff8c00");

echo.defineStyler("darkorchid", "color: #9932cc");
echo.defineStyler("bgDarkorchid", "background-color: #9932cc");

echo.defineStyler("darkred", "color: #8b0000");
echo.defineStyler("bgDarkred", "background-color: #8b0000");

echo.defineStyler("darksalmon", "color: #e9967a");
echo.defineStyler("bgDarksalmon", "background-color: #e9967a");

echo.defineStyler("darkseagreen", "color: #8fbc8f");
echo.defineStyler("bgDarkseagreen", "background-color: #8fbc8f");

echo.defineStyler("darkslateblue", "color: #483d8b");
echo.defineStyler("bgDarkslateblue", "background-color: #483d8b");

echo.defineStyler("darkslategray", "color: #2f4f4f");
echo.defineStyler("bgDarkslategray", "background-color: #2f4f4f");

echo.defineStyler("darkslategrey", "color: #2f4f4f");
echo.defineStyler("bgDarkslategrey", "background-color: #2f4f4f");

echo.defineStyler("darkturquoise", "color: #00ced1");
echo.defineStyler("bgDarkturquoise", "background-color: #00ced1");

echo.defineStyler("darkviolet", "color: #9400d3");
echo.defineStyler("bgDarkviolet", "background-color: #9400d3");

echo.defineStyler("deeppink", "color: #ff1493");
echo.defineStyler("bgDeeppink", "background-color: #ff1493");

echo.defineStyler("deepskyblue", "color: #00bfff");
echo.defineStyler("bgDeepskyblue", "background-color: #00bfff");

echo.defineStyler("dimgray", "color: #696969");
echo.defineStyler("bgDimgray", "background-color: #696969");

echo.defineStyler("dimgrey", "color: #696969");
echo.defineStyler("bgDimgrey", "background-color: #696969");

echo.defineStyler("dodgerblue", "color: #1e90ff");
echo.defineStyler("bgDodgerblue", "background-color: #1e90ff");

echo.defineStyler("firebrick", "color: #b22222");
echo.defineStyler("bgFirebrick", "background-color: #b22222");

echo.defineStyler("floralwhite", "color: #fffaf0");
echo.defineStyler("bgFloralwhite", "background-color: #fffaf0");

echo.defineStyler("forestgreen", "color: #228b22");
echo.defineStyler("bgForestgreen", "background-color: #228b22");

echo.defineStyler("gainsboro", "color: #dcdcdc");
echo.defineStyler("bgGainsboro", "background-color: #dcdcdc");

echo.defineStyler("ghostwhite", "color: #f8f8ff");
echo.defineStyler("bgGhostwhite", "background-color: #f8f8ff");

echo.defineStyler("gold", "color: #ffd700");
echo.defineStyler("bgGold", "background-color: #ffd700");

echo.defineStyler("goldenrod", "color: #daa520");
echo.defineStyler("bgGoldenrod", "background-color: #daa520");

echo.defineStyler("greenyellow", "color: #adff2f");
echo.defineStyler("bgGreenyellow", "background-color: #adff2f");

echo.defineStyler("grey", "color: #808080");
echo.defineStyler("bgGrey", "background-color: #808080");

echo.defineStyler("honeydew", "color: #f0fff0");
echo.defineStyler("bgHoneydew", "background-color: #f0fff0");

echo.defineStyler("hotpink", "color: #ff69b4");
echo.defineStyler("bgHotpink", "background-color: #ff69b4");

echo.defineStyler("indianred", "color: #cd5c5c");
echo.defineStyler("bgIndianred", "background-color: #cd5c5c");

echo.defineStyler("indigo", "color: #4b0082");
echo.defineStyler("bgIndigo", "background-color: #4b0082");

echo.defineStyler("ivory", "color: #fffff0");
echo.defineStyler("bgIvory", "background-color: #fffff0");

echo.defineStyler("khaki", "color: #f0e68c");
echo.defineStyler("bgKhaki", "background-color: #f0e68c");

echo.defineStyler("lavender", "color: #e6e6fa");
echo.defineStyler("bgLavender", "background-color: #e6e6fa");

echo.defineStyler("lavenderblush", "color: #fff0f5");
echo.defineStyler("bgLavenderblush", "background-color: #fff0f5");

echo.defineStyler("lawngreen", "color: #7cfc00");
echo.defineStyler("bgLawngreen", "background-color: #7cfc00");

echo.defineStyler("lemonchiffon", "color: #fffacd");
echo.defineStyler("bgLemonchiffon", "background-color: #fffacd");

echo.defineStyler("lightblue", "color: #add8e6");
echo.defineStyler("bgLightblue", "background-color: #add8e6");

echo.defineStyler("lightcoral", "color: #f08080");
echo.defineStyler("bgLightcoral", "background-color: #f08080");

echo.defineStyler("lightcyan", "color: #e0ffff");
echo.defineStyler("bgLightcyan", "background-color: #e0ffff");

echo.defineStyler("lightgoldenrodyellow", "color: #fafad2");
echo.defineStyler("bgLightgoldenrodyellow", "background-color: #fafad2");

echo.defineStyler("lightgray", "color: #d3d3d3");
echo.defineStyler("bgLightgray", "background-color: #d3d3d3");

echo.defineStyler("lightgreen", "color: #90ee90");
echo.defineStyler("bgLightgreen", "background-color: #90ee90");

echo.defineStyler("lightgrey", "color: #d3d3d3");
echo.defineStyler("bgLightgrey", "background-color: #d3d3d3");

echo.defineStyler("lightpink", "color: #ffb6c1");
echo.defineStyler("bgLightpink", "background-color: #ffb6c1");

echo.defineStyler("lightsalmon", "color: #ffa07a");
echo.defineStyler("bgLightsalmon", "background-color: #ffa07a");

echo.defineStyler("lightseagreen", "color: #20b2aa");
echo.defineStyler("bgLightseagreen", "background-color: #20b2aa");

echo.defineStyler("lightskyblue", "color: #87cefa");
echo.defineStyler("bgLightskyblue", "background-color: #87cefa");

echo.defineStyler("lightslategray", "color: #778899");
echo.defineStyler("bgLightslategray", "background-color: #778899");

echo.defineStyler("lightslategrey", "color: #778899");
echo.defineStyler("bgLightslategrey", "background-color: #778899");

echo.defineStyler("lightsteelblue", "color: #b0c4de");
echo.defineStyler("bgLightsteelblue", "background-color: #b0c4de");

echo.defineStyler("lightyellow", "color: #ffffe0");
echo.defineStyler("bgLightyellow", "background-color: #ffffe0");

echo.defineStyler("limegreen", "color: #32cd32");
echo.defineStyler("bgLimegreen", "background-color: #32cd32");

echo.defineStyler("linen", "color: #faf0e6");
echo.defineStyler("bgLinen", "background-color: #faf0e6");

echo.defineStyler("magenta", "color: #ff00ff");
echo.defineStyler("bgMagenta", "background-color: #ff00ff");

echo.defineStyler("mediumaquamarine", "color: #66cdaa");
echo.defineStyler("bgMediumaquamarine", "background-color: #66cdaa");

echo.defineStyler("mediumblue", "color: #0000cd");
echo.defineStyler("bgMediumblue", "background-color: #0000cd");

echo.defineStyler("mediumorchid", "color: #ba55d3");
echo.defineStyler("bgMediumorchid", "background-color: #ba55d3");

echo.defineStyler("mediumpurple", "color: #9370db");
echo.defineStyler("bgMediumpurple", "background-color: #9370db");

echo.defineStyler("mediumseagreen", "color: #3cb371");
echo.defineStyler("bgMediumseagreen", "background-color: #3cb371");

echo.defineStyler("mediumslateblue", "color: #7b68ee");
echo.defineStyler("bgMediumslateblue", "background-color: #7b68ee");

echo.defineStyler("mediumspringgreen", "color: #00fa9a");
echo.defineStyler("bgMediumspringgreen", "background-color: #00fa9a");

echo.defineStyler("mediumturquoise", "color: #48d1cc");
echo.defineStyler("bgMediumturquoise", "background-color: #48d1cc");

echo.defineStyler("mediumvioletred", "color: #c71585");
echo.defineStyler("bgMediumvioletred", "background-color: #c71585");

echo.defineStyler("midnightblue", "color: #191970");
echo.defineStyler("bgMidnightblue", "background-color: #191970");

echo.defineStyler("mintcream", "color: #f5fffa");
echo.defineStyler("bgMintcream", "background-color: #f5fffa");

echo.defineStyler("mistyrose", "color: #ffe4e1");
echo.defineStyler("bgMistyrose", "background-color: #ffe4e1");

echo.defineStyler("moccasin", "color: #ffe4b5");
echo.defineStyler("bgMoccasin", "background-color: #ffe4b5");

echo.defineStyler("navajowhite", "color: #ffdead");
echo.defineStyler("bgNavajowhite", "background-color: #ffdead");

echo.defineStyler("oldlace", "color: #fdf5e6");
echo.defineStyler("bgOldlace", "background-color: #fdf5e6");

echo.defineStyler("olivedrab", "color: #6b8e23");
echo.defineStyler("bgOlivedrab", "background-color: #6b8e23");

echo.defineStyler("orangered", "color: #ff4500");
echo.defineStyler("bgOrangered", "background-color: #ff4500");

echo.defineStyler("orchid", "color: #da70d6");
echo.defineStyler("bgOrchid", "background-color: #da70d6");

echo.defineStyler("palegoldenrod", "color: #eee8aa");
echo.defineStyler("bgPalegoldenrod", "background-color: #eee8aa");

echo.defineStyler("palegreen", "color: #98fb98");
echo.defineStyler("bgPalegreen", "background-color: #98fb98");

echo.defineStyler("paleturquoise", "color: #afeeee");
echo.defineStyler("bgPaleturquoise", "background-color: #afeeee");

echo.defineStyler("palevioletred", "color: #db7093");
echo.defineStyler("bgPalevioletred", "background-color: #db7093");

echo.defineStyler("papayawhip", "color: #ffefd5");
echo.defineStyler("bgPapayawhip", "background-color: #ffefd5");

echo.defineStyler("peachpuff", "color: #ffdab9");
echo.defineStyler("bgPeachpuff", "background-color: #ffdab9");

echo.defineStyler("peru", "color: #cd853f");
echo.defineStyler("bgPeru", "background-color: #cd853f");

echo.defineStyler("pink", "color: #ffc0cb");
echo.defineStyler("bgPink", "background-color: #ffc0cb");

echo.defineStyler("plum", "color: #dda0dd");
echo.defineStyler("bgPlum", "background-color: #dda0dd");

echo.defineStyler("powderblue", "color: #b0e0e6");
echo.defineStyler("bgPowderblue", "background-color: #b0e0e6");

echo.defineStyler("rosybrown", "color: #bc8f8f");
echo.defineStyler("bgRosybrown", "background-color: #bc8f8f");

echo.defineStyler("royalblue", "color: #4169e1");
echo.defineStyler("bgRoyalblue", "background-color: #4169e1");

echo.defineStyler("saddlebrown", "color: #8b4513");
echo.defineStyler("bgSaddlebrown", "background-color: #8b4513");

echo.defineStyler("salmon", "color: #fa8072");
echo.defineStyler("bgSalmon", "background-color: #fa8072");

echo.defineStyler("sandybrown", "color: #f4a460");
echo.defineStyler("bgSandybrown", "background-color: #f4a460");

echo.defineStyler("seagreen", "color: #2e8b57");
echo.defineStyler("bgSeagreen", "background-color: #2e8b57");

echo.defineStyler("seashell", "color: #fff5ee");
echo.defineStyler("bgSeashell", "background-color: #fff5ee");

echo.defineStyler("sienna", "color: #a0522d");
echo.defineStyler("bgSienna", "background-color: #a0522d");

echo.defineStyler("skyblue", "color: #87ceeb");
echo.defineStyler("bgSkyblue", "background-color: #87ceeb");

echo.defineStyler("slateblue", "color: #6a5acd");
echo.defineStyler("bgSlateblue", "background-color: #6a5acd");

echo.defineStyler("slategray", "color: #708090");
echo.defineStyler("bgSlategray", "background-color: #708090");

echo.defineStyler("slategrey", "color: #708090");
echo.defineStyler("bgSlategrey", "background-color: #708090");

echo.defineStyler("snow", "color: #fffafa");
echo.defineStyler("bgSnow", "background-color: #fffafa");

echo.defineStyler("springgreen", "color: #00ff7f");
echo.defineStyler("bgSpringgreen", "background-color: #00ff7f");

echo.defineStyler("steelblue", "color: #4682b4");
echo.defineStyler("bgSteelblue", "background-color: #4682b4");

echo.defineStyler("tan", "color: #d2b48c");
echo.defineStyler("bgTan", "background-color: #d2b48c");

echo.defineStyler("thistle", "color: #d8bfd8");
echo.defineStyler("bgThistle", "background-color: #d8bfd8");

echo.defineStyler("tomato", "color: #ff6347");
echo.defineStyler("bgTomato", "background-color: #ff6347");

echo.defineStyler("turquoise", "color: #40e0d0");
echo.defineStyler("bgTurquoise", "background-color: #40e0d0");

echo.defineStyler("violet", "color: #ee82ee");
echo.defineStyler("bgViolet", "background-color: #ee82ee");

echo.defineStyler("wheat", "color: #f5deb3");
echo.defineStyler("bgWheat", "background-color: #f5deb3");

echo.defineStyler("whitesmoke", "color: #f5f5f5");
echo.defineStyler("bgWhitesmoke", "background-color: #f5f5f5");

echo.defineStyler("yellowgreen", "color: #9acd32");
echo.defineStyler("bgYellowgreen", "background-color: #9acd32");

echo.defineStyler("rebeccapurple", "color: #663399");
echo.defineStyler("bgRebeccapurple", "background-color: #663399");
echo
  // The "root" styler is the raw CSS that will be inherited by all other
  // stylers, custom and internal.
  // .defineRootStyler("color: blue ;")
  .defineStyler("asTag", echo.bold.padded.rounded)
  // Notice that the next two stylers are building off of "asTag", a previously
  // defined custom styler.
  .defineStyler("asWarning", echo.asTag.bgGold.black)
  .defineStyler("asAlert", echo.asTag.bgRed.white)
  // Custom stylers can also just be raw CSS.
  .defineStyler(
    "asMarquee",
    "font-size: 30px ; padding: 20px 30px 20px 30px ; display: inline-block ; border: 3px solid gold ;"
  );
