<div>
  <p>
    <strong
      >This history of the club and it's founder was prepared in 2001 by Peter
      Sanderson, a founder member, as a talk to the 2001 AGM.
    </strong>
  </p>
  <p>
    In the mid-1960's, two parishioners of St. Edward's' Church in Whitley Bay,
    Pat Connors and John Lewis formed an amateur boxing club and approached the
    Parish Priest, the late Father Joseph Scarr, and asked for permission to use
    for boxing training the gymnasium in the old St. Edward's' School building.
    This is on the site of the shops and flats and what is now the Parish Centre
    in Roxburgh Terrace. He agreed provided that the club was called St.
    Edward's Amateur Boxing Club - the origin of the A.B.C. in our club title.
  </p>
  <p>
    During its the nineteen years history under the direction of founder and
    coach, now the late Pat Connors, and secretary also now the late John Lewis,
    the club raised a great deal of money for charity, its greatest effort was
    in 1982 when a boxing tournament raised £4,000 for the South Atlantic
    (Falklands) Fund.
  </p>
  <p>
    At that time, throughout the country were 79 Army Youth Teams (AYT's) two of
    which were in the North East, number 8 and number 6 under the banners of the
    Royal Regiment of Fusiliers and the Coldstream Guards respectively, all
    regular soldiers.
  </p>
  <p>
    No. 8, stationed at Fenham Barracks in Newcastle had under their wing
    Wallbottle High School, King Edward School Morpeth and sixteen Tyne and Wear
    Army Cadet Detattachments. They approached St. Edward's Boxing Club so that
    a variety of sporting activities became available catering for boys and
    young men, girls and adults - as well as boxing - fencing, rifle shooting,
    and fell walking.
  </p>
  <p>
    From then, once a week, St. Edward's Rifle Shooting Team went to the 25 yard
    0.22 indoor range at Fenham Barracks and in the summer time to the open air
    ranges at Ponteland firing .303 Lee Enfield rifles. And at other times on
    fellwalking and rock climbing expeditions all under the supervision and
    support of trained soldiers who also provided transport and equipment where
    necessary.
  </p>
  <p>Again it was No.8 AYT who suggested a winter walking programme.</p>
  <p>
    Boxing club secretary John Lewis contacted schools to see if they were
    interested in taking part, and the only school to reply was La Sagesse
    Convent School for Girls in Newcastle.
  </p>
  <p>
    Picture the scene in early 1971, - a mixture of about sixty convent school
    girls and some twenty amateur boxers climbing on to army lorries for the
    first outing of what was intended to be a one off winter walking programme.
  </p>
  <p>
    John Lewis had advised that boots should be worn. He was somewhat surprised
    when many of the girls arrived for the winter walk, from Rothbury to Elsdon,
    in 1971 vogue for thigh length fashion boots.
  </p>
  <p>
    It was then taht John heard about the famous 42 mile walk over the North
    Yorkshire Moors - The Lyke Wake. A crossing was planned and executed with
    the Royal Navy providing transport - no doubt arranged by another St.
    Edward's Parishioner - the bearded Commander 'Ginger' Blackloch RN.
  </p>
  <p>
    Monthly walks were now being arranged, more people were joining the
    fellwalking group group, parents and other adults among them. A private
    coach was being used now - the initial charge was 30 pence and the 53 seater
    coach was always full.
  </p>
  <p>
    As the years passed, it was decided that rather than a double up on the
    coaches, a programme of fortnightly walks be introduced.
  </p>
  <p>
    "A great wealth of expertise has developed in the club over the years and it
    has gone from strength to strength" said John Lewis.
  </p>
  <p>
    Economy cuts saw the end of the Army Youth Teams, the boxing club ceased
    with the untimely death of the coach and founder Pat Connors, but the fell
    walkers remain and continue to take the name of St. Edwards ABC Fellwalkers
    to the high places.
  </p>
  <p>
    During it's first 20 years, about 800 walkers joined the club, the actual
    walking strength each year was in the region of 150.
  </p>
  <p>
    For a number of years, the club took part in fund raising walks for UNICEF,
    then concentrated on raising money for the mountain and fell rescue teams in
    those areas members walked.
  </p>
  <p>
    Recalling the 1971 start, John Lewis again said "It was just going to be a
    winter walking programme - and that would be the end of it".
  </p>
  <p>
    During the club's 20th anniversary celebrations, John retired, having been
    the first and only secretary for all those years.
  </p>
  <p>
    <img src="/assets/image/johnlewis.jpg" alt="" width="214" height="254" />
    John Lewis
  </p>
  <p>
    The older and long serving Club members will remember the three great dane
    dogs which John brought with him on the walks - not all together - he only
    owned one at a time - all named after Kings of England, Alfie, Charlie and
    Harry. Each were taken out from being young to fully grown and at times had
    to be lifted over awkward stiles and fences. When on the coach took up
    position either lying or sitting in the aisle at the front alongside the
    nearside front par of seats - called by John - 'The Company Office' from
    where he conducted business calling the roll on the outward journey, making
    announcements and generally keeping his troops in order. He had been in the
    Cameronian Scottish Highland Regiment during the war and those of us who had
    been in the forces either during that time or for National Service could
    appreciate the humour in this.
  </p>
  <p>
    On the mid-morning coffee break on a walk or the lunch stop, one of the dogs
    if it could escape from John's clutches would wander around the sitting
    walkers with great slobbering chops looking for something to eat and would
    take a fancy to someone, man or woman, their sex didn't match and would try
    to mount them. This was eventually cured by a visit to the vet - by the dog
    that was.
  </p>
  <p>
    While on the subject of sex, John liked the company of both men and women,
    but differentiated between them - men were men and women were non-men. Was
    he a male-chauvinist? - maybe, but he was also a character and had it not
    been for him the club would not have happened.
  </p>
  <p>
    On the return journey after the pub stop and John had taken a few bottles of
    McEwans export, he would call out for any new members to come to the front
    of the coach for the 'new members induction ceromony'. Some came prepared
    but others were caught unawares. A poem had to be recited or a song sung and
    usually after the first line or first verse of a song had been sung everyone
    on the coach joined in. I can only remember one person refusing, which was
    probably a worse experience than getting up. The "Road to Mandalay" and
    "Don't turn away the Cameronians' - they will be needed by and by" were two
    songs with which John was liable to burst into song.
  </p>
  <p>
    He was also a keen photographer and a very good one. After de-bussing and
    before the walkers set-off, a group photograph was taken and others were
    taken during the walk that he was on. On reaching home, the films were
    developed and printed by himself in his dark room and on the next walk,
    prints were on sale 25p each.
  </p>
  <p>
    A dramatic account of each walk accompanied by a photograph was usually sent
    for publication in the following weeks edition, to the Whitley Guardian.
  </p>
  <p>
    For those who had known him, he had worked in the Newcastle City Architects
    department heading many well-known projects including the interior design
    of, and the tower for Newcastle Civic Centre, and the re-furbishment of the
    Theatre Royal.
  </p>
  <p>
    In 1966, he was appointed as a Magistrate to the Whitley Bay Court where he
    soon became a favourite subject among local journalists and was a regular
    letter writer to the Whitley Guardian on numerous subjects. He was also a
    regular voice as 'John from Whitley Bay' on Alan Jobson's Metro Radio 'Night
    Owls' phone-in programme.
  </p>
  <p>
    He died in November 1997 aged 77 and his funeral service was held at - wher
    else - St. Edward's Church on November 29th.
  </p>
  <p>
    A special Sunday walk was arranged in the Lake District during which his
    ashes were scattered on Silver How - overlooking Grasmere after having had a
    few drops of Whisky added by his son Willy.
  </p>
</div>
