{#if walk?.date}
  <div id="walkDetail">
    <p class="date">
      {dispDate(walk.date)}
      {#if walk.bankHoliday === 1}
        <span class="bhwe">(Bank Hol. weekend)</span>
      {/if}
    </p>
    <p class="area">
      {walk.area}
      {#if walk.byLine}<span class="byline">({walk.byLine})</span>{/if}
    </p>
    <p class="region">{walk.region?.regname ?? ""}</p>
    <p><span>Bus Departure: </span>{walk.time} a.m.</p>
    <p><span>Organizer: </span>{walk.organizer}</p>
    {#if walk.map}
    <p><span>Maps: </span>{walk.map ?? ""}</p>
    {/if}
    {#if walk.details === "Y"}
      <p>See <a href={details(walk)}>Route Details</a> for this walk.</p>
    {/if}
  </div>
{/if}

<script>
  import { format } from "date-fns";
  export let walk = {};
  $: console.log("_walkHighlights", walk);
  const dispDate = (dat) => {
    return dat && format(new Date(dat), "EEEE, do MMMM yyyy ");
  };
  const details = (walk) => {
    return `/#/programme/walk/${walk.date}`;
  };
</script>

<style>
  #walkDetail {
    font-size: 13px;
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    text-align: left;
  }

  #walkDetail p.date {
    font-size: smaller;

    & .bhwe {
      font-style: italic;
    }
  }
  #walkDetail p.area {
    color: #663300;
    font-size: larger;

    & span.byline {
      font-size: smaller;
      font-style: italic;
    }
  }

  #walkDetail p {
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  #walkDetail p span {
    display: inline;
    color: #999999;
  }
</style>
