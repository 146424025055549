<svelte:component this={layout.get(page) ?? Default}>
  <Router {routes} />
</svelte:component>

<script>
  import { initGA } from "./analytics.js";
  initGA();
  import "./myGlobal.css";
  import { onMount } from "svelte";
  import "./utilities/echo";
  import "./utilities/echo-colors";
  import "./utilities/testEcho";
  import Router, { location as page } from "svelte-spa-router";
  import { routes } from "./routes.js";

  // Include our layouts

  import Default from "./layouts/default.svelte";
  import { getWalkData, getWalkDataC } from "$lib/WalksMixin.js";

  let layout = new WeakMap();
  let navbarOpts = new WeakMap();
  let navHideable = new WeakMap();

  let params;
  let loc;

  onMount(async () => {
    // const version = await packageJson().version;
    const response = await getWalkData("");
    const server = await response.json();

    console.log("Website package version", "packageVersion");
    console.log("ApiServer ", server);
  });
  const parse = (ctx, next) => {
    console.log("router ctx:", ctx, location);
    loc = ctx.pathname;
    params = ctx.params;
    next();
  };
  // const show = (comp) => (page = comp);
</script>
