<div class="eventBox">
  <h3 class:hide={!event?.title} class="title">
    {@html title}
  </h3>
  <div class="detailbody">
    <h5 class:hide={!event?.start}>{@html dispDate(event)}</h5>
    <h5 class:hide={!event?.location} class="loc">{@html event?.location}</h5>
    <div class:hide={!event?.description}>{@html description}</div>
  </div>
</div>

<script>
  import { format, parseISO } from "date-fns";
  import showdown from "showdown";
  const converter = new showdown.Converter({
    emoji: true,
    tables: true,
    strikethrough: true,
  });
  export let event;
  const log = (...rest) =>
    echo.log(echo.black.bgYellow.bold("EventDetail"), ...rest);
  const superScribe = (text) => {
    const regex = /(\d+)(st|nd|rd|th)([ \s,.])/g;
    return text.replaceAll(regex, "$1<sup>$2</sup>$3");
  };
  const s2 = superScribe("the 50th or 1st, or 2nd.");
  log("superscribe", s2);
  // the 50th or 1st, or 2nd.

  $: description = superScribe(converter.makeHtml(event?.description || ""));
  $: title = superScribe(converter.makeHtml(event?.title || ""));
  $: log("event", event, description);
  const dispDate = (event) => {
    log(event);
    const fmt = "EEE do MMMM yyyy";
    // return format(new Date(dat), 'dddd, d MMMM yyyy ');
    const { multiDay, start, end, time } = event || {};
    if (!start) return "";
    log(multiDay, start, end, time);
    let d1 = format(parseISO(start), fmt);
    const a1 = d1.split(" ");
    // a1[1] = `${a1[1].substr(0, a1[1].length - 2)}<sup>${a1[1].substr(
    //   -2
    // )}</sup>`;
    d1 = a1.join(" ");
    const tim = time == "" ? "" : ", " + time;
    if (!multiDay || !end) return superScribe(d1 + tim);
    const d2 = format(parseISO(end), fmt);
    const a2 = d2.split(" ");
    let res = "";
    if (a1[2] == a2[2])
      res = `${a1[0]} ${a1[1]} to ${a2[0]} ${a2[1]} ${a1[2]} ${a1[3]}${tim}`;
    else if (a1[3] == a2[3])
      res = `${a1[0]} ${a1[1]} ${a1[2]} to ${a2[0]} ${a2[1]} ${a2[2]} ${a1[3]}${tim}`;
    else res = d1 + " to " + d2 + tim;
    return superScribe(res);
  };
</script>

<style lang="postcss">
  .hide {
    display: none;
  }
  h3.title {
    color: #cc3300;
    font-weight: bold;
  }
  .eventBox {
    border: 2px solid #999999;
  }
  .title {
    padding: 2px 5px;
    text-align: center;
    border-bottom: 1.5px solid #999999;
    background-color: rgb(255, 245, 247);
  }
  h5 {
    font-size: 0.9em;
  }
  .detailbody {
    padding: 0.3em;
  }
</style>
