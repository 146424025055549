<!-- <div class:fixedMenu> -->
<svelte:window bind:innerWidth />
<button
  type="button"
  class:fixedMenu={$menuStatus.fixed}
  on:click={() => ($menuStatus.hidden = !$menuStatus.hidden)}
>
  <img
    src={`/assets/image/${$menuStatus.hidden ? "menu" : "icon-Cancel"}.svg`}
    alt
  />
</button>

<!-- </div> -->
<script context="module">
  import { writable } from "svelte/store";

  export const menuStatus = writable({ hidden: true, fixed: false });
</script>

<script>
  // import { menu } from "./menuStatus";
  export let fixedMenu;
  let innerWidth;
  $: {
    if (fixedMenu) {
      if ($menuStatus.fixed && innerWidth <= 800) {
        $menuStatus.hidden = true;
      }
      $menuStatus.fixed = innerWidth > 800;
      if ($menuStatus.hidden && innerWidth > 800) $menuStatus.hidden = false;
    }
    console.log("Memutoggle", innerWidth, {
      fixedMenu,
      menuStatus: $menuStatus,
    });
  }
  // export let menuHidden = writeable(!fixedMenu);
</script>

<style lang="postcss">
  img {
    cursor: pointer;
    display: inline-block;
    margin: 2px;
    padding: 2px;
    width: 1.3em;
    border: 3px solid red;
    border-radius: 0.1em;
  }

  @media print {
  }
  @media only screen and (min-width: 801px) {
    .fixedMenu {
      display: none;
    }
  }
</style>
