<Lightbox imgs={pictures} />

<script>
  import { onMount } from "svelte";
  import {
    getGalleryDataF,
    getGalleryDataURL,
    expandSrcset,
  } from "@components/galleryMixin.mjs";

  import Lightbox from "@components/gallery/Lightbox.svelte";
  let pictures = [];
  onMount(async () => {
    let data = await getGalleryDataF("getPictures");
    const directory = data.directory;
    console.log("getAlbums data", data, directory);
    pictures = data.map((pic) => {
      const imageUrl = getGalleryDataURL(directory, pic.filename);
      const srcset = expandSrcset(pic.srcset);
      // pic.albumTitle = pic.album.title.replace(/&amp;/gi, "&");
      return { ...pic, imageUrl, srcset };
    });
    console.log("getPictures", data);
  });
</script>
