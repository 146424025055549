<div>
  <ul class="simpleList">
    <li>
      The whole process starts in Autumn when the Walks Co-ordinator encourages
      members to submit their favourite walks for consideration as part of the
      following years programme. By members acceptance of this encouragement,
      the Club is now able to offer a wider range of walks and walking areas.
      This process begins by circulating a list of the following year’s
      programme dates around the bus on the walks. Members are invited to
      suggest a destination for which they would organize 5 walks on their
      chosen date.
    </li>
    <li>
      The Walks Co-ordinator considers the submissions in total and resolves by
      discussion with the relevant members any issues such as repeated
      destinations and the spread across all of the areas that we frequent, eg.
      Northumberland, Yorkshire Dales. The Walks Co-ordinator submits a
      resulting draft programme to the Committee. The final programme is the
      responsibility of the whole Committee.
    </li>
    <li>
      Members who have offered to organise a particular day are requested to
      provide to the Walks Co-ordinator with a very broad indication of their
      proposed walks, sufficient to give an indication of the route of the bus.
      For example, a destination might be approached from the north or from the
      south. That might make a significant difference to bus mileage.
    </li>
    <li>
      The Walks Co-ordinator then prepares a Programme sheet in the usual format
      showing the destination, the geographical area and the organizer for each
      date and also a corresponding list of the likely bus routes. These are
      given to the club Treasurer for the purposes of negotiating a cost for the
      bus for the following programme year.
    </li>
    <li>
      Towards the end of the current year, the following year’s Programme will
      be placed on the club website.
    </li>
    <li>
      In due course, the organizer has to present the detail of the walks,
      (description, maps etc), to the committee six weeks prior to the day for
      the walks for consideration by the Committee.
    </li>
    <li>
      If you are thinking of volunteering to organize a walk there are three
      guidelines that are there to give detailed advice and assistance on all of
      the activities involved in organising a day’s outing. These are  <a
        href="/#/Walk_Organization/organizer's_guidelines"
        >Walk Organizers Guidelines</a
      >, <a href="/#/Walk_Organization/choice_of_walk">Choice of Walk</a> and
      <a href="/#/Walk_Organization/bus_logistics">Bus Logistics</a>.
    </li>
  </ul>
</div>
