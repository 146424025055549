<div class="container">
  {#each photos as photo, i}
    <div class={`photo ${photo.status}`}>
      <img src={photo.img} alt="photograph" />
      <div class="progress">
        <div class="bg">{photo.file.name}</div>
        <div class="bg">{photo.progress}</div>
      </div>
      <div class="message bg">{photo.message ?? ""}</div>
    </div>
  {/each}
  <input
    hidden="true"
    id="file-to-upload"
    type="file"
    accept=".png,.jpg"
    bind:this={fileInput}
    bind:files
    on:change={() => processFiles()}
    multiple
  />
  <button
    class="upload-btn"
    on:click={() => fileInput.click()}
    hidden={photos.length > 0}>Select Files to Upload</button
  >
  <button class="upload-btn" on:click={reset} hidden={photos.length === 0}
    >Reset</button
  >
</div>

<!-- <main
  style="transform: translateX(67.1px) translateY(-53.55px) translateZ(100px) scale(1) rotateX(8.0325deg) rotateY(17.9732deg);"
>
  <span style="--blur: 43.15178571428571px;" /><span
    style="--blur: 33.90841836734693px;"
  /><span style="--blur: 24.66505102040816px;" /><span
    style="--blur: 15.421683673469387px;"
  /><span style="--blur: 6.178316326530615px;" /><span
    style="--blur: 1px;"
  /><span style="--blur: 1px;" /><span style="--blur: 1px;" />
</main> -->

<script>
  import {
    processUploadPhotos,
    uploadPhotos,
  } from "@components/GalleryMixin.mjs";
  export let album;
  export let photographer;
  let fileInput;
  let files = [];
  let photos = [];
  const currentUploads = new Set();
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
  async function processFiles() {
    //     import { readFile } from "node:fs/promises";

    // const fileName = "./sample.txt";
    // const body = new FormData();
    // const blob = new Blob([await readFile(fileName)]);
    // body.set("field1", "val1");
    // body.set("field2", "val2");
    // body.set("file1", blob, fileName);

    console.log({ files, fileInput });
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      photos[i] = { file, progress: "?", status: "uploading" };

      reader.onload = (e) => {
        photos[i].img = e.target.result;
        photos[i].progress = "⬆︎ 0%";
        uploadPhoto(photos[i], i);
      };
    }
  }
  function reset() {
    photos = [];
    files = [];
  }

  async function onSubmit(e) {
    for (const photo of photos) {
      await uploadPhoto(photo);
    }
  }
  async function uploadPhoto(photo, i) {
    while (currentUploads.size >= 4) {
      console.log("zzz...", photo.file.name);
      await sleep(500);
    }
    currentUploads.add(photo.file.name);
    const formData = new FormData();
    formData.append("photo", photo.file);
    formData.append("albumTitle", album.title);
    photographer && formData.append("photographer", photographer);

    const onUploadProgress = (progressEvent) => {
      photos[i].progress = `⬆︎ ${Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )}%`;

      console.log({ percentCompleted: photo.progress });
    };
    const result = await uploadPhotos(formData, onUploadProgress);
    console.log(result);
    currentUploads.delete(photo.file.name);
    if (result.status !== 200) {
      photos[i].progress = `${result.status} ❌`;
      photos[i].status = "error";
      photos[i].message = result.message;
      return;
    }
    photos[i].progress = "✔️";
    photos[i].status = "completed";
    // const { error, filename, tempFile } = result;
    // if (error || !filename) {
    //   photos[i].progress = "❌";
    //   photos[i].status = "error";
    //   return;
    // }
    // photos[i].progress = "uploaded";
    // const data = {
    //   albumTitle: album.title,
    //   photographer,
    //   filename,
    //   tempFile,
    // };
    // processUploadPhotos(data)
    //   .then(() => {
    //     photos[i].progress = "✔️";
    //     photos[i].status = "completed";
    //   })
    //   .catch((err) => {
    //     photos[i].progress = `error:${err.code}: ${err.message}`;
    //     photos[i].status = "error";
    //   });
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .photo {
    width: 400px;
    border: thin solid black;
    display: grid;
    grid-template-rows: 40px auto 40px;
    grid-template-columns: 50px 1fr 50px;
    flex-direction: row;
    position: relative;
    background: linear-gradient(to top, white, 20%, chocolate);
    justify-content: center;
    align-content: center;
  }
  .photo.error {
    background: linear-gradient(to top, white, 20%, red);
  }
  .photo.completed {
    background: linear-gradient(to top, white, 20%, green);
  }
  .photo img {
    grid-area: 1 / 1 / 4 / 4;
    justify-self: center;
    align-self: center;
    display: inline-block;
    height: 256px;
    width: 256px;
    object-fit: contain;
    object-position: center;
  }
  .photo .bg {
    border-radius: 1.5em;
    background-color: rgb(255 255 255 / 0.75);
    padding: 0.1em 0.2em;
  }
  .photo div.progress {
    grid-area: 1 / 1 / 2 / 4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .photo div.message {
    grid-area: 3 / 1 / 4 / 4;
    justify-self: center;
    align-self: center;
  }
  .hidden {
    display: none;
  }

  .upload-btn {
    width: 256px;
    height: 32px;
    background-color: black;
    font-family: sans-serif;
    color: white;
    font-weight: bold;
    border: none;
  }

  .upload-btn:hover {
    background-color: white;
    color: black;
    outline: black solid 2px;
  }
</style>
