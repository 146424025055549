{#if list && list.length > 0}
  <div id="pickbuttons" v-show="list">
    <input
      type="button"
      value="◀️◀️"
      disabled={isFirst}
      on:click={() => chngd(0)}
    />
    <input
      type="button"
      value="◀️"
      disabled={isFirst}
      on:click={() => chngd(-1)}
    />
    <input
      type="button"
      value="▶️"
      disabled={isLast}
      on:click={() => chngd(+1)}
    />
    <input
      type="button"
      value="▶️▶️"
      disabled={isLast}
      on:click={() => chngd(2)}
    />
  </div>
{/if}

<script>
  export let list = [];
  export let eventId;
  const log = (...rest) =>
    echo.log(echo.white.bgGreen.bold("PickButtons"), ...rest);
  $: eventIndex = list.findIndex((e) => e.id === eventId);
  $: isFirst = eventIndex === 0;
  $: isLast = eventIndex === list.length - 1;
  $: console.log("pick buttons - events", eventId, eventIndex);

  const chngd = (i) => {
    let ind;
    if (i === 2) ind = list.length - 1;
    else if (i === 0) ind = 0;
    else ind = eventIndex + i;
    if (ind < 0 || ind >= list.length) return;
    console.log("chngd", i, ind, eventIndex);
    eventId = list[ind].id;
  };
</script>

<style></style>
