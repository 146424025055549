<MetaTags
  title="St.Edwards Fellwalkers | North Tyneside based walking club"
  description="St.Edwards Fellwalkers is a North Tyneside based walking club that walks in the north of England and the Scottish borders. Walk with us to improve your fitness and health."
  canonical="https://stedwardsfellwalkers.co.uk/"
  keywords={[
    "St.Edwards Fellwalkers",
    "walking club",
    "outdoors",
    "exercise",
    "northumberland",
    "lakes",
    "durham",
    "yorkshire dales",
    "north york moors",
    "scottish borders",
    "cumbria",
    "jumping",
    "fitness",
  ]}
  openGraph={{
    title: "St.Edwards Fellwalkers | North Tyneside based walking club",
    description:
      "St.Edwards Fellwalkers is a North Tyneside based walking club that walks in the north of England and the Scottish borders. Walk with us to improve your fitness and health.",
    // image: "https://stedwardsfellwalkers.co.uk/images/squatting.jpg",
    url: "https://stedwardsfellwalkers.co.uk/",
    type: "website",
    // images: [
    //   {
    //     url: "https://stedwardsfellwalkers.co.uk/images/squatting.jpg",
    //     width: 800,
    //     height: 600,
    //     alt: "Squatting",
    //   },
    //   {
    //     url: "https://stedwardsfellwalkers.co.uk/images/lunging.jpg",
    //     width: 900,
    //     height: 800,
    //     alt: "Crawling",
    //   },
    //   {
    //     url: "https://stedwardsfellwalkers.co.uk/images/crawling.jpg",
    //     alt: "Jumping",
    //   },
    //   {
    //     url: "https://stedwardsfellwalkers.co.uk/images/jumping.jpg",
    //   },
    // ],
    site_name: "St.Edwards Fellwalkers",
  }}
/>
<div class={`page myPage `} class:fullWidth use:cssVars={styleVars}>
  <div class="head">
    <Banner />
  </div>
  <!-- <div class="mToggle screenonly" on:click={toggleMenu}>
    {#if menuHidden}
    <img class="border screenonly" src="/assets/image/menu.svg" alt />
    {:else}
    <img class="border screenonly" src="/assets/image/icon-Cancel.svg" alt />
    {/if}
  </div> -->
  <div class="body">
    <div class="main">
      <div class="header">
        <PageHeader loc={$location} />
        <div class="mToggle screenonly">
          <MenuToggle {fixedMenu} />
        </div>
      </div>
      <slot />
    </div>
    <NavBar loc={$location} />
    <!-- <div class="menu screenonly" class:hidden={menuHidden}> -->
    <!-- </div> -->
  </div>
  <div class="foot myFooter width-1of1">
    <p>
      Send mail to
      <a href="mailto:webmaster@stedwardsfellwalkers.co.uk">Webmaster</a>
      with questions or comments about this site.
    </p>
    <span>packageVersion</span>
    <Authentication />
  </div>
</div>

<script>
  // import axios from 'axios';
  import { MetaTags } from "svelte-meta-tags";
  import NavBar from "@components/navBarStEds.svelte";
  import Banner from "@components/bannerStEds.svelte";
  import PageHeader from "@components/PageHeader.svelte";
  import MenuToggle from "@components/MenuToggle.svelte";
  import { menuStatus } from "@components/MenuToggle.svelte";
  import Authentication from "@components/authentication.svelte";
  import { location } from "svelte-spa-router";

  import cssVars from "svelte-css-vars";

  // import router from "page";
  let fixedMenu = true;

  $: console.log("default layout:", $location);
  const hideable = [
    "library",
    "albums",
    "album",
    "upload",
    "latestPictures",
    "OSMap",
    "maintEvents",
    "programme/walk",
  ];
  const wide = ["OSMap", "library", "hk"];
  let fullWidth = false;
  let menuHidden = false;
  const styleVars = {};
  // let signin = loc.includes("gallery");

  $: {
    fullWidth = false;
    for (const tag of wide) {
      if ($location.includes(tag)) {
        fullWidth = true;
        break;
      }
    }
  }

  $: {
    fixedMenu = true;
    for (const tag of hideable) {
      if ($location.includes(tag)) {
        fixedMenu = false;
        menuHidden = true;
        break;
      }
    }
    menuStatus.set({ hidden: true, fixed: fixedMenu });
    // styleVars = {
    //   menuWidth:
    //     !$menuStatus.fixedMenu && $menuStatus.menuHidden ? "0px" : "145px",
    // };
  }

  $: console.log("menuStatus", fixedMenu, menuHidden);
  const toggleMenu = () => {
    menuHidden = !menuHidden;
  };
</script>

<style lang="postcss">
  :root {
    --badge: {
      position: absolute;
      top: -8px;
      right: -8px;
      font-size: 0.7em;
      color: white;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      box-shadow: 0 0 1px #333;
    };
  }
  @media print {
    .head.head {
      display: none;
    }
  }
  a,
  a:visited {
    color: #cc3300;
    text-decoration: none;
  }
  a:hover {
    color: white;
    background-color: #cc3300;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  .mToggle {
    width: 100%;
    text-align: right;
    cursor: pointer;
  }
  .myPage {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    height: 100%;
    min-height: 100%;
    width: 100%;
    background: #ffffff;
    & .body {
      text-align: left;

      display: grid;
      grid-template-columns: minmax(0, 1fr) auto;
      flex-direction: row;

      & .main {
        flex: 1 1 auto;
        overflow-x: hidden;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);

        & p {
          padding-bottom: 0.5em;
        }

        & > div {
          padding: 0 0.3em 0 0.3em;
        }
      }
    }
  }
  .myPage:not(.fullWidth) .body .main > div {
    max-width: 800px;
  }
  @media only screen and (min-width: 801px) {
    .myPage {
      margin-left: auto;
      margin-right: auto;
      width: 800px;
      max-width: 800px;
      &.fullWidth {
        width: 100vw;
        max-width: 100vw;
      }

      &.hideableMenu {
        &.fullWidth .body .main > div {
          /* min-width: 100vw; */
          width: 100vw;
        }
        & .body .main > div {
          min-width: 800px;
          width: 800px;
        }
      }
    }
  }
  .header {
    display: grid;
    grid-template-columns: 1fr 60px;
    flex-direction: row;
    justify-content: space-between;
  }
  .myFooter {
    border: 0;
    border-top: 1px solid #ccc;
    color: #666;
    background: #eee;
    padding: 0;
    text-align: left;
    clear: both;
    font-size: x-small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .eventsPage {
    padding: 10px 5px;
  }

  .box {
    border: medium double #999999;
    margin: 2px auto;
    padding: 6px;
    display: inline-block;
    clear: right;
    min-width: 200px;
    max-width: 320px;
  }

  .right {
    float: right;
    clear: right;
  }

  .profile {
    float: right;
    display: block;
  }

  @media screen {
    .printonly {
      display: none;
    }
  }
  @media print {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: sans-serif;
    }
    .screenonly,
    .col-right,
    .col-left,
    .menu,
    .head,
    .hideableMenu,
    .foot {
      display: none;
    }
    .myPage {
      /* max-width: 100vw; */
      /* width: 100vw; */
      background: #ffffff;
      width: 790px;
      max-width: 790px;
    }
  }
</style>
