<div id="showbox" class="slides">
  {#if !pictures.length}
    <Loading typ="1" />
  {/if}
  <!-- <transition-group name="slide" tag="div" class="img-slider"> -->
  <div class="slideshow" class:portrait={pic.height - pic.width > 0}>
    <div class="album">
      {#if timer}
        <img
          on:click={stopRotation}
          class="fa-icon"
          src="/assets/image/fa-solid/pause.svg"
          alt="stop slideshow"
        />
      {:else}
        <img
          on:click={startRotation}
          class="fa-icon"
          src="/assets/image/fa-solid/play-circle.svg"
          alt="start slideshow"
        />
      {/if}
      <a href={`/gallery/album/${pic.aid}`} title="click to see this album"
        >{pic.albumTitle}</a
      >
      <span>&nbsp;</span>
    </div>
    <div class="pic">
      <a href="/#/gallery/latestPictures">
        <transition name="slide" class="img-slider">
          {#if pic.srcset}
            <div class="img">
              <img
                class="lazyload"
                src={pic.imageURL}
                srcset={pic.srcset}
                data-src={pic.imageURL}
                data-srcset={pic.srcset}
                sizes="auto"
                alt=""
              />
            </div>
          {/if}
        </transition>
      </a>
    </div>
    <div class="labelling">
      {#if timer}
        <img
          on:click={stopRotation}
          class="fa-icon"
          src="/assets/image/fa-solid/pause.svg"
          alt=""
        />
      {:else}
        <img
          on:click={startRotation}
          class="fa-icon"
          src="/assets/image/fa-solid/play-circle.svg"
          alt=""
        />
      {/if}
      <div>
        <div class="photographer" {startRotation}>
          {pic.photographer ?? ""}
        </div>
        <div class="title">{pic.title ?? ""}</div>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</div>

<script>
  import { onMount, onDestroy } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import { location } from "svelte-spa-router";

  import {
    getGalleryDataF as getGalleryData,
    getGalleryDataURL,
    expandSrcset,
    curSlideShowPic,
  } from "@components/GalleryMixin.mjs";

  let timer = null;
  let hidden = null;
  let visibilityChange = null;
  let pic;
  let ctrlChar;
  const test = false;
  let srcset;

  let pictures = [];
  onMount(async () => {
    const data = await getGalleryData("getPictures");

    // let data = await res.json();
    for (const pic of data) {
      pic.imageUrl = getGalleryDataURL(pic.album.directory, pic.filename);
      pic.srcset = expandSrcset(pic.srcset);
      // pic.thumbUrl = imageURL(pic, "thumb");
      // pic.fullUrl = imageURL(pic, "filename");
      pic.albumTitle = pic.album.title.replace(/&amp;/gi, "&");
    }

    pictures = data;
    console.log("getPictures", pictures);
    timer || startRotation();

    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
  });

  onDestroy(async () => {
    stopRotation();
  });
  // biome-ignore lint/suspicious/noConfusingLabels: <explanation>
  $: pic = pictures[$curSlideShowPic] || {};

  // biome-ignore lint/suspicious/noConfusingLabels: <explanation>
  $: ctrlChar = test ? "&#10074;&#10074;" : "&#9658;";

  // $: let lpictures =
  //   // for testing - only show landscape pictures
  //   picture.filter(pic => pic.pheight - pic.pwidth > 0);
  // };

  const nextPic = () => {
    $curSlideShowPic = ($curSlideShowPic + 1) % pictures.length;
    pic = pictures[$curSlideShowPic];
    console.log("nextPic", $curSlideShowPic);
  };
  // const userCanHover = () => (userCanHover = true);

  const imageName = (pic, path) => {
    const url = getGalleryData;
    if (!pic.filepath || !pic.thumb) return null;
    return pic
      ? `https://gallery.stedwardsfellwalkers.co.uk/${pic.filepath}${pic.thumb}`
      : "";
  };
  const mapAlbum = (src = "") => {
    // process.env.NODE_ENV !== 'production'
    const loc = "https://gallery.stedwardsfellwalkers.co.uk/albums";
    return src.replace(/albums/g, loc);
  };
  const shiftit = () => {
    console.log("shiftit", $curSlideShowPic);
    nextPic();
  };
  const orientation = (pic) => {
    return pic.height > pic.width ? "portrait" : "landscape";
  };
  const toggleRotation = () => {
    timer ? stopRotation() : startRotation();
  };
  const startRotation = () => {
    console.log("starting rotation");
    const nextPicIfNotHidden = () => {
      console.log("nextPicIfNotHidden", document, document[hidden], $location);
      if ($location === "/") nextPic();
    };
    timer = setInterval(nextPicIfNotHidden, 5000);
    console.log("starting rotation");
  };
  const stopRotation = () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  };
</script>

<style lang="postcss">
  img.fa-icon {
    width: 1em;
    height: 1em;
    padding: 0.1em;
  }

  .img-slider {
    overflow: hidden;
    position: relative;
    height: 300px;
    width: 332px;
  }

  #showbox {
    position: relative;
    transition: transform 0.5s linear;
    transform: none;
    border-style: none;
    border-color: #cc9999;
    box-sizing: border-box;
    margin: -2px 1px 2px 1px;

    border-width: 0 3px 3px 3px;
    min-height: 250px;

    & > div {
      display: flex;
      justify-content: center;
    }
  }
  .slideshow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 10pt;
    height: 300px;
    width: 100%;
    margin: 0;
    & .album,
    & .labelling {
      display: flex;
      justify-content: space-between;
      padding-top: 0.5em;
      & img {
        width: 1em;
        height: 1em;
      }
    }
    &:not(.portrait) {
      & .labelling img {
        opacity: 0;
      }
    }
    &.portrait {
      flex-direction: row-reverse;

      & .album {
        writing-mode: vertical-rl;
        padding-right: 0.5em;
        & img,
        & span {
          opacity: 0;
        }
      }
      & .labelling {
        writing-mode: vertical-rl;
        padding-bottom: 1em;
        padding-left: 0.2em;
      }
      & .pic img {
        height: 300px;
        width: 280px;
      }
    }
  }

  .slideshow a {
    text-decoration: none;
    font-size: 10pt;
    height: auto;
  }
  .slideshow .photographer {
    font-size: 80%;
    font-style: italic;
    text-align: right;
  }
  .slideshow .title {
    font-weight: bold;
    text-align: center;
  }
  .slideshow .album {
    font-weight: bold;
    text-align: center;
  }
  .slideshow .pic {
    flex: 1 1 auto;
    /*display:table-cell;*/
    width: 100%;
    text-align: center;
    vertical-align: top;
  }
  .slideshow .img {
    width: 100%;
    /*display:table-cell;*/
    /*text-align:center;*/
  }

  .slideshow img {
    border-style: none;
    width: 332px;
    height: 252px;
    object-fit: contain;
  }
  .slideshow .labelling {
    height: auto;
    padding-right: 0.5em;
  }
</style>
