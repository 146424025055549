<section>
  <a
    href="https://webcollect.org.uk/stedwardsfellwalkers/category/walk"
    class="WCbutton">Walk</a
  >
  <a href="https://webcollect.org.uk/stedwardsfellwalkers">WebCollect</a>
  <h3>Test of embedding Web Collect</h3>
  <div class="table">
    <div class="header">
      <span>Date</span><span>Venue</span><span>Seats Available</span>
    </div>
    {#each walks as { walkDate, venue, inventory }}
      <div>
        <span>{walkDate}</span><span>{venue}</span><span>{inventory}</span>
      </div>
    {/each}
  </div>
</section>

<script>
  import { onMount } from "svelte";
  import _ from "lodash";
  import { push } from "svelte-spa-router";
  import { format } from "date-fns";
  const stedsServer = process.env.API_SERVER;

  let walks = [];
  onMount(async () => {
    const now = format(new Date(), "dd-MM-yyyy");
    const bWalks = [];
    const res = await fetch(`${stedsServer}/webcollect/bookings/${now}`);
    let events = await res.json();
    console.log("events", events);
    // events = events.map((e) => e.get({ plain: true }));
    console.log("events", events.length);
    // const prevOrders = {};

    for (const event of events) {
      console.log(event.name);
      for (const walkDay of event.event_products) {
        let {
          start_date: walkDate,
          label: venue,
          capacity,
          sell_from,
          inventory,
        } = walkDay;
        if (/^Credit/i.test(venue)) continue;
        console.log({ walkDate, venue, inventory });
        bWalks.push({ walkDate, venue, inventory });
      }
    }
    walks = bWalks;
    console.log(walks);
  });
</script>

<style>
  .table {
    width: auto;
    & .header {
      background-color: #ccc;
      & span {
      }
    }
    & span {
      text-align: center;
      display: inline-block;
      border: solid black thin;
    }
    & > div {
      span:nth-child(1) {
        width: 6em;
      }
      span:nth-child(2) {
        padding-left: 0.75em;
        width: 12em;
        text-align: left;
      }
      span:nth-child(3) {
        width: 8em;
      }
    }
  }
  .WCbutton {
    padding: 3px 15px 2px 5px;
    background: url("https://webcollect.org.uk/media/images/org-buttons/btn-bg-5.png")
      no-repeat right center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }
</style>
