<div class="holder" class:hidden={$menuStatus.hidden}>
  <div class="vertMenu">
    <p>Home</p>
    <NavLink to="/">Home</NavLink>
    <NavLink to={onlineBookings} target="_blank">Online Bookings</NavLink>
    <p>About the Club</p>
    <NavLink to="/About_the_Club/introduction">Introduction</NavLink>
    <NavLink to="/About_the_Club/history">History</NavLink>
    <NavLink to="/About_the_Club/membership">Membership</NavLink>
    <NavLink to="/About_the_Club/new_members_and_guests">
      New Members & Guests
    </NavLink>
    <NavLink to="/About_the_Club/bookings">Bookings</NavLink>

    <p>Walk Organization</p>
    <NavLink to="/Walk_Organization/introduction">Introduction</NavLink>
    <NavLink to="/Walk_Organization/planning">Planning</NavLink>
    <NavLink to="/Walk_Organization/organizer_s_guidelines">
      Organizer's Guidelines
    </NavLink>
    <NavLink to="/Walk_Organization/choice_of_walk">Choice of Walk</NavLink>
    <NavLink to="/Walk_Organization/bus_logistics">Bus Logistics</NavLink>

    <p>Programme</p>
    {#each latestYears ?? [] as { year }}
      <NavLink to={"/programme/year/" + year}>{year} Walks</NavLink>
    {/each}
    <NavLink to="/programme/library">Walks Library</NavLink>
    <NavLink to="/programme/otherEvents">Other Events</NavLink>

    <p>Guidelines</p>
    <NavLink to="/guidelines/introduction">Introduction</NavLink>
    <NavLink to="/guidelines/personal_equipment">Personal Equipment</NavLink>
    <NavLink to="/guidelines/member_s_role">Member's Role</NavLink>
    <NavLink to="/guidelines/leader_s_role">Leader's Role</NavLink>
    <NavLink to="/guidelines/emergency_planning">Emergency Planning</NavLink>
    <NavLink to="/guidelines/emergency_procedures">
      Emergency Procedures
    </NavLink>

    <p>Other</p>
    <NavLink to="/other/contact_us">Contact Us</NavLink>
    <NavLink to="/other/links">Links</NavLink>
    <NavLink to="/gallery/albums">Gallery</NavLink>
    <NavLink to="/other/downloads">Downloads</NavLink>
    {#if $isAdmin || isUploader}
      <p>HouseKeeping</p>
      {#if $isAdmin}
        <NavLink to="/hk/maintEvents">Events Maintenance</NavLink>
        <NavLink to="/hk/gallery/maint">Gallery Maintenance</NavLink>
      {/if}
      <NavLink to="/hk/webcollect">Web Collect</NavLink>
      <NavLink to="/gallery/upload">Add Photos</NavLink>
    {/if}
  </div>
</div>

<script>
  /* This component expects to be used by the parent as an item in a display:flex
  flex-direction:row */
  import NavLink from "./NavLink.svelte";
  import { getWalkDataC } from "$lib/WalksMixin.js";
  import { onMount } from "svelte";
  import { menuStatus } from "./MenuToggle.svelte";
  import { isAdmin, isUploader } from "@store/authentication";

  let latestYears = [];
  onMount(async () => {
    latestYears = await getWalkDataC(`GetLatestYears`);
    // console.log("navBar0", latestYears);
    // let response = await getWalkData(`GetLatestYears`);
    // latestYears = await response.json();
    // console.log("navBar", latestYears);
  });
  const onlineBookings =
    "https://webcollect.org.uk/sec/index.php?page=login&action=login.sign_in_or_sign_up&organisation_id=3735";
  $: console.log("menuStatus", $menuStatus);
</script>

<style lang="postcss">
  @media print {
    .holder.holder {
      display: none;
    }
  }
  .holder {
    width: 145px;
    overflow-x: hidden;
    /* flex: 0 0 145px; */
    display: table-cell;
    display: block;
    transition: all 0.5s linear;
  }
  .holder.hidden {
    width: 0;
    display: none;
    flex: 0 0 0px;
  }
  /* .vertMenu {
    & p,
    & div {
      overflow-x: hidden;
      max-height: 1em;
    }
  }  */

  .vertMenu {
    width: 145px;
    font-stretch: condensed;
    border-left: thin solid #cc3300;
    padding-left: 4px;
    margin-left: 2px;

    & p {
      font-size: small;
      color: rgb(88, 76, 77);
      margin-bottom: 0;
      margin-top: 0.5em;
      width: 100%;
      border: 1px solid rgb(88, 76, 77);
      background-color: rgb(235, 206, 208);
      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
