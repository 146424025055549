// Include our Routes
import Home from "./routes/home/index.svelte";
import E404 from "./routes/404.svelte";

import Introduction from "./routes/About_the_Club/introduction.svelte";
import History from "./routes/About_the_Club/history.svelte";
import Membership from "./routes/About_the_Club/membership.svelte";
import New_members_and_guests from "./routes/About_the_Club/new_members_and_guests.svelte";

import Walks_intro from "./routes/Walk_Organization/introduction.svelte";
import Planning from "./routes/Walk_Organization/planning.svelte";
import Choice_of_walk from "./routes/Walk_Organization/choice_of_walk.svelte";
import Bus_logistics from "./routes/Walk_Organization/bus_logistics.svelte";
import Bookings from "./routes/About_the_Club/bookings.svelte";
import Organizer_s_guidelines from "./routes/Walk_Organization/organizer_s_guidelines.svelte";

import Year from "./routes/programme/year/[year].svelte";
import Library from "./routes/programme/library/library.svelte";
import OtherEvents from "./routes/programme/otherEvents.svelte";
import OSMap from "./routes/programme/OSMap/OSMap.svelte";
import WalkDetails from "./routes/programme/walk/WalkDetails.svelte";

import Guidelines_intro from "./routes/guidelines/introduction.svelte";
import Personal_equipment from "./routes/guidelines/personal_equipment.svelte";
import Member_s_role from "./routes/guidelines/member_s_role.svelte";
import Leader_s_role from "./routes/guidelines/leader_s_role.svelte";
import Emergency_planning from "./routes/guidelines/emergency_planning.svelte";
import Emergency_procedures from "./routes/guidelines/emergency_procedures.svelte";

import Contact_us from "./routes/other/contact_us.svelte";
import Links from "./routes/other/links.svelte";
import Albums from "./routes/gallery/albums.svelte";
import Album from "./routes/gallery/album/[albumId].svelte";
import Upload from "./routes/gallery/upload.svelte";
import LatestPictures from "./routes/gallery/LatestPictures.svelte";
import Downloads from "./routes/other/downloads.svelte";
import MaintEventsProg from "./routes/hk/maintEventsProg.svelte";
import MaintGallery from "./routes/hk/gallery/maint.svelte";
import MaintGalleryAlbum from "./routes/hk/gallery/[albumId].svelte";
import WebCollect from "./routes/hk/webCollect.svelte";

// Include our layouts

export const routes = {
  "/": Home,
  "/home": Home,
  "/About_the_Club/introduction": Introduction,
  "/About_the_Club/history": History,
  "/About_the_Club/membership": Membership,
  "/About_the_Club/new_members_and_guests": New_members_and_guests,
  "/About_the_Club/bookings": Bookings,

  "/Walk_Organization/introduction": Walks_intro,
  "/Walk_Organization/planning": Planning,
  "/Walk_Organization/choice_of_walk": Choice_of_walk,
  "/Walk_Organization/bus_logistics": Bus_logistics,
  "/Walk_Organization/organizer_s_guidelines": Organizer_s_guidelines,

  "/programme/year/:year": Year,
  "/programme/library": Library,
  "/programme/walk/:walkId": WalkDetails,
  "/programme/OSMap/:walkId/:no/:noRoutes": OSMap,
  "/programme/otherEvents": OtherEvents,

  "/guidelines/introduction": Guidelines_intro,
  "/guidelines/personal_equipment": Personal_equipment,
  "/guidelines/member_s_role": Member_s_role,
  "/guidelines/leader_s_role": Leader_s_role,
  "/guidelines/emergency_planning": Emergency_planning,
  "/guidelines/emergency_procedures": Emergency_procedures,

  "/other/contact_us": Contact_us,
  "/other/links": Links,
  "/gallery/albums": Albums,
  "/gallery/albums/:year": Albums,
  "/gallery/album/:albumId": Album,
  "/gallery/upload": Upload,
  "/gallery/latestPictures": LatestPictures,
  "/other/downloads": Downloads,
  "/hk/maintEvents": MaintEventsProg,
  "/hk/gallery/maint": MaintGallery,
  "/hk/gallery/maint/:year": MaintGallery,
  "/hk/gallery/maintAlbum/:albumId": MaintGalleryAlbum,
  "/hk/webCollect": WebCollect,
  // "/hk/gallery/maintAlbum/:year/:albumId": MaintGalleryAlbum,
  "*": E404,
};
