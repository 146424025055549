<div class="page">
  <div class="section blocks">
    <EventsDetails where="H">xxx</EventsDetails>
    {#if nextWalk}
      <div class="nextwalk">
        <h3>Next Walk</h3>
        <WalkHighlight walk={nextWalk}>yyy</WalkHighlight>
      </div>
    {/if}
    <ShowSlides>zzz</ShowSlides>
  </div>

  <div class="section content">
    <p>Welcome to the web site of St. Edward's Fellwalkers.</p>
    <p>
      We are an all-weather, all-year round walking club based on northeast
      Tyneside.<br /> We are an independent club, affiliated to the Ramblers association,
      and, despite the name are not associated with St. Edward’s Church (see History
      to learn about the origin of our club and it’s name).
    </p>
    <p>
      Walks take place every other Saturday in areas such as Northumberland,
      Durham, Cumbria, Scotland, and both the Yorkshire Dales and Moors.
      <br />
      There is usually a choice of five walks, which vary in distance and height
      to cater for the individual walkers requirements.
    </p>
    <hr />
    <p>
      All you need is a strong pair of boots, waterproofs and a rucksack.&nbsp;
      Sorry we can't guarantee the weather but we can guarantee a warm welcome
      as St. Edward's is a friendly club with a lively social programme.
    </p>
    <hr />
    <p>
      Follow the links on the right hand side to find out more about the club
      and our activities
    </p>
  </div>
</div>

<script>
  import WalkHighlight from "../programme/year/_WalkHighlight.svelte";
  import ShowSlides from "./_ShowSlides.svelte";
  import EventsDetails from "../events/_EventsDetails.svelte";

  import { getWalkData } from "$lib/WalksMixin";
  import { format } from "date-fns";

  import { onMount } from "svelte";
  let nextWalk = false;
  let head = () => {
    return { title: "Home" };
  };
  onMount(async () => {
    console.log("getNextWalk dispatch");
    let dat = format(new Date(), "yyyy-MM-dd");
    if (dat <= nextWalk.date) return;
    let res = await getWalkData(`getWalkDetails`, dat);
    const data = await res.json();
    nextWalk = data.error ? false : data.details;
    console.log("home nextwalk", nextWalk, res);
  });

  //   computed: {
  //     ...mapState(['pictures', 'curPic', 'nextWalk']),
  //   },
  // };
</script>

<style>
  .nextwalk {
    border: 3px solid #cc9999;
    margin: 0 1px;
    padding: 0 0 2px 2px;
  }
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .section.blocks {
    width: 100%;
    max-width: 340px;
    overflow-x: hidden;
  }
  .content {
    height: auto;
  }

  /* Desktop Styles */
  @media only screen and (min-width: 650px) {
    .page {
      display: block;
    }
    .page .blocks {
      float: right;
      margin: 2px;
      max-width: 340px;
      border: 3px solid #c99;
      position: relative;
    }
  }
</style>
