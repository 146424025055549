import axios from "axios";
import { camelCase } from "lodash";
import {
  getVisitorId,
  getAuthseq,
  getMemId,
  getAuthUser,
} from "@store/authentication";
import { writable } from "svelte/store";
// import getenv from "getenv";

export const curSlideShowPic = writable(0);
// const { GALLERY_SERVER, UPLOAD_SERVER } = process.env;
export const galleryServer = process.env.GALLERY_SERVER;
export const uploadServer = process.env.UPLOAD_SERVER;
export const getGalleryURL = (func, ...args) => {
  let url = (args ?? []).reduce(
    (res, arg) => `${res}/${arg}/`,
    camelCase(func),
  );
  if (url.slice(-1) === "/") url = url.slice(0, -1);
  return `${galleryServer}/cpg/${url}`;
};
export const getGalleryDataURL = (...parts) =>
  `${galleryServer}/galleryData/${parts.join("/").trim()}`;
export const expandSrcset = (srcset) => {
  let items = srcset.split(",");
  const newSrcset = "";
  items = items.map((item) => {
    const [, file, size] = item.match(/^\s*(\S+)[ ]+(\S+)/);
    return `${getGalleryDataURL(file)} ${size}`;
  });
  return items.join(", ");
};

export const getGalleryDataF = async (...args) => {
  console.log("args", args);
  const url = getGalleryURL(...args);
  console.log("url", url);
  const ret = await fetch(url);
  const data = await ret.json();
  console.log("getGalleryDataF", args, data);
  return data;
};
export const getGalleryData = (...args) => {
  const url = getGalleryURL(...args);
  return axios.get(url);
};
export const uploadPhotos = async (formData, onUploadProgress) => {
  try {
    console.log("authUser", getAuthUser());
    const url = getGalleryURL("processUpload");
    console.log("posting to ", url);
    const response = await axios.post(url, formData, {
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Auth-Token": await getVisitorId(),
        authseq: getAuthseq(),
        "member-id": getMemId(),
        memId: getMemId(),
      },
      onUploadProgress,
    });
    const { data, status, message } = response;
    const returnedData = { ...data, status, message };

    console.log("postRequest uploadPhotos returned:", returnedData);
    return returnedData;
  } catch (error) {
    console.log(error);
    const { statusCode, message } = error.response.data;
    return { status: statusCode, message };
  }
};
export const processUploadPhotos = async (param) =>
  await doGalleryChanges("processUpload", param);
// export const processUploadPhotos = async function (param) {
//   try {
//     const url =
//       getGalleryURL("processUpload") +
//       "?" +
//       new URLSearchParams(param).toString();

//     console.log("posting to ", url);
//     const response = await axios.get(url, {
//       cache: "no-cache",
//       headers: {
//         Accept: "application/json",
//         "Auth-Token": getVisitorId(),
//         authseq: getAuthseq(),
//       },
//     });

//     let returnedData = response.data;

//     console.log(`postRequest uploadPhotos returned:`, returnedData);
//     return returnedData;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const doGalleryChanges = async (path, param) => {
  try {
    const url = `${getGalleryURL(path)}?${new URLSearchParams(param).toString()}`;

    console.log("posting to ", url);
    const response = await axios.get(url, {
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Auth-Token": await getVisitorId(),
        authseq: getAuthseq(),
        "member-id": getMemId(),
      },
    });

    const returnedData = response.data;

    console.log("postRequest uploadPhotos returned:", returnedData);
    return returnedData;
  } catch (error) {
    console.log(error);
  }
};

// export const postGalleryJson = async function (
//   request,
//   data,
//   onUploadProgress
// ) {
//   try {
//     console.log("postChanges", { request, data });
//     // data["Auth-Token"] = getVisitorId();
//     // data.authseq = getAuthseq();
//     var config = {};
//     const res = await axios.post(uploadServer, data, {
//       cache: "no-cache",
//       headers: {
//         Accept: "application/json",
//         "Auth-Token": getVisitorId(),
//         authseq: getAuthseq(),
//       },
//       onUploadProgress,
//     });
//     // const res = await fetch(getGalleryURL(request),  {
//     //   method: "POST",
//     //   cache: "no-cache",
//     // mode: "cors",
//     //   headers: {
//     //     Accept: "application/json",
//     //     "Content-Type": "application/json",
//     //     "Auth-Token": getVisitorId(),
//     //     authseq: getAuthseq(),
//     //   },
//     //   onUploadProgress,
//     //   body: JSON.stringify(data),
//     // });
//     let returnedData = res.data;

//     console.log(`postRequest ${request} returned:`, returnedData);
//     return returnedData;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const postGalleryChanges = async function (
//   request,
//   data,
//   onUploadProgress
// ) {
//   try {
//     console.log("postChanges", { request, data });
//     // data["Auth-Token"] = getVisitorId();
//     // data.authseq = getAuthseq();
//     var config = {};
//     const res = await axios.post(getGalleryURL(request), data, {
//       cache: "no-cache",
//       headers: {
//         Accept: "application/json",
//         "Auth-Token": getVisitorId(),
//         authseq: getAuthseq(),
//       },
//       onUploadProgress,
//     });

//     let returnedData = res.data;

//     console.log(`postRequest ${request} returned:`, returnedData);
//     return returnedData;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const imageURL = (pic, mode = "normal") => {
  const name = `${pic.filepath}${pic[mode]}`;
  return pic ? `${galleryServer}/${name}` : "";
};
export const mapAlbum = (src) => {
  // process.env.NODE_ENV !== 'production'
  const loc = getGalleryURL("albums");
  const ret = src.replace(/albums/g, loc);
  return ret;
};
export default {
  methods: {
    imageURL,
    getGalleryData,
    mapAlbum,
    uploadPhotos,
    curSlideShowPic,
  },
};
