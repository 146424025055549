<div>
  <p>
    Organizing a day's walks for the club is a challenging but very satisfying
    activity. To the person who hasn't done it before, it may at first appear to
    be a daunting task, capable of being undertaken by only the highest of
    skilled map readers and organizers. This is, in fact, not the case and can
    be undertaken by anyone who is a keen walker with a basic knowledge of map
    reading. This guide is designed to explain the whole process.
  </p>

  <p>
    There are, in the club, a number of people who regularly organize walks and
    will be only too willing to offer assistance when and where it is required.
    You will never be asked to organize your first walks solely by yourself. An
    important point to bear in mind, is that although a walk organizer may
    arrange the walks for the day he or she will not be ultimately held
    responsible should things not go to plan. That responsibility falls to the
    Committee who have previously met and discussed the walks and arrangements
    for the day.
  </p>

  <p>
    The following list is intended to provide guidance for walk organizers that
    are interested in organizing a day's walks for the club. The list items are
    largely in chronological order.
  </p>

  <h5>Area</h5>
  <p>
    First, you need to identify the area in which you'd like to organize the
    walks. Remember to ensure it is not too far for a day's trip and is
    accessible for the bus. New organizers might find it useful to choose one of
    their favourite walk areas that they know well.
  </p>

  <h5>Finishing point</h5>
  <p>
    Determine the point where all walks will finish. Walks may arrive back at
    the bus at different times so it is always necessary to have a pub and/or
    coffee shop for people to wait at. Remember also that the destination should
    have reasonable access roads for the bus and somewhere it can safely wait
    (e.g. car park, lay-by) whilst waiting for walkers to return.
  </p>

  <h5>The walks</h5>
  <p>
    Next, you need to identify 5 walks of differing distances that will end at
    the chosen destination. The Choice of Walk guideline gives a general
    description of the range of the 5 walks that should be available for members
    to choose from on a walk day. However it is important to remember that the
    lengths of the walks will vary depending on the terrain and height involved
    and may be less than those general values. Naturally a day’s walks in the
    Lake District will be generally shorter than those along the Northumberland
    Coast. A good guide is to look at past walks in that area for determining
    the 5 different walks.
  </p>

  <p>
    It is essential that walks can be completed in the time available with the
    general aim to be completed around 4.30pm, usually after around 6 hours.
    Walks at distant locations such as The Lakes may not have 6 hours available.
    Organizers should reflect on the following rule of thumb for estimating the
    time of their walks.
  </p>
  <ol>
    <li>Walk 1 Average walking speed 6 kms per hour.</li>
    <li>Walk 2 Average walking speed 5 kms per hour.</li>
    <li>Walk 3 Average walking speed 4 kms per hour.</li>
    <li>Walk 4 Average walking speed 3-4 kms per hour.</li>
    <li>Walk 5 Average walking speed 2-3 kms per hour.</li>
  </ol>
  <p>You should add 10 minutes for each 300m of climb.</p>
  <p>You should add 5 minutes per 300m of descent in the Lakes.</p>
  <p>You should add 30minutes for breaks.</p>

  <h5>Drop off points</h5>
  <p>
    Having identified the 5 walks, the drop-off points should be examined. This
    is where the bus will need to stop to let off the walkers. Again, reasonable
    bus access needs to be borne in mind, as well as the logical progression in
    which walks will be dropped off. Try to avoid having to stop the bus on busy
    roads or corners, and any routes that will involve narrow country lanes and
    narrow or low bridges. Signposted bus stops are considered to be valid
    drop-off places and lay-bys are ideal. Also if the bus needs to turn around
    at any point, bear in mind the length of the coach for it to be able to
    manoeuvre. Google Earth, Google Maps & Street View are useful tools for
    assessing potential bus routes and especially proposed drop-off points.
  </p>

  <h5>Morning and Evening stops</h5>
  <p>
    The next thing you need to decide upon is where the bus is going to stop in
    the morning for a breakfast stop and in the evening to have drinks and a
    meal. The breakfast stops tend to be frequently used and advice on these is
    easily found by asking a Committee member.
  </p>

  <p>
    The destination should be checked that it has facilities for an evening
    meal. At many towns there is a choice of pubs that serve meals but in
    smaller villages there may only be one pub. Websites are often inaccurate so
    the organizer must contact one or more pubs to ensure that meals are
    available no later than 5pm. If they are not, we would generally move on at
    5pm to a location nearer home where food is available.
  </p>
  <p>
    It is good practice to let proprietors know of our intended visit, unless
    there are multiple facilities at the destination, when the promise of a
    visit is usually best avoided.
  </p>

  <h5>Reconnaissance</h5>
  <p>
    Reconnaissance of the walks is not essential though some members prefer to
    do that in order to determine that the rights-of-way match those on the map,
    and whether any features vital to the walk are actually there, such as
    bridges for river crossings. Again, Google Earth can give a very good
    indication whether there are visible tracks across an area ,for example
    across moorland. The club also has fairly up-to-date maps that can be
    borrowed.
  </p>

  <h5>Initial draft proposals</h5>
  <p>
    One map should be prepared which shows all walks, including start and finish
    points and mileages with an estimate of ascent and descent. Ordnance Survey
    software (and others) is also available at a reasonable cost which measures
    all aspects of a walk route. Information on proposed breakfast and evening
    stops should also be given.
  </p>

  <p>
    The above should be given to the Walk Co-ordinator at least 6 weeks prior to
    the walk date for scrutinising. It is important not to complete all of the
    maps at this stage as the Walk Co-ordinator may suggest changes to the
    routes based on past experiences in that area or walking time available. The
    views of the Committee may also be sought if there are any issues with the
    proposals.
  </p>

  <h5>Final maps</h5>
  <p>
    Once the routes have been approved, either mark all 5 walks on a suitable
    map base or alternatively staple together individual maps of the 5 walks.
    Either way, two sets will be required to be circulated around the bus on the
    day for members to select their walk. A map should be prepared and given to
    each individual walk leader, preferably well in advance of the walk day.
  </p>

  <p>
    The maps may be on any convenient scale. It is the walk leader’s duty to
    transfer the information onto a 1;25,000 OS map.
  </p>

  <p>
    The Walks Co-ordinator will arrange to have the approved walks placed on the
    club website.
  </p>

  <h5>Walk leaders</h5>
  <p>
    When you have the routes approved and maps prepared you can approach members
    of the club to lead the various walks. There are again several people within
    the club who are willing to do this.
  </p>

  <h5>Attend committee meeting</h5>
  <p>
    The walk organizer for the Saturday may, if they wish, attend the Committee
    meeting on the Wednesday before the walk. If there are no issues or last
    minute changes there is no requirement to attend. However, it is the
    organizer’s duty to ensure that the Walk Co-ordinator has all information
    that is needed for the Saturday walk at the prior Committee meeting, and in
    particular the bus copy maps.
  </p>

  <h5>Assistance</h5>
  <p>
    Remember, help is always at hand and should you need assistance at any stage
    of the process, no matter how trivial it may seem, then please approach the
    club Walks Co-ordinator or any member of the Committee.
  </p>

  <p>
    If there is one piece of advice above all others, it is to submit draft
    proposals as early as possible.
  </p>
</div>
