<div>
  <p>An emergency is any event which prevents normal completion of a walk.</p>

  <h5>On the Fells:</h5>

  <ul class="simpleList">
    <li>
      If you become split from the main body of your group and are unable see
      which way your group has gone;
      <strong>STAY PUT</strong><br />
      Use emergency whistle, signals etc, to attract attention as quickly as possible
      better to do it sooner than later. (six blasts with a minute's silence, repeated
      even when the acknowledgement is heard - three blasts during the minute's silence).
    </li>
    <li>
      If assistance is needed, attempt to contact other St Edward's walking
      parties, to advise them of the situation BEFORE contacting emergency
      services (an extreme situation may over-ride the initial Club contact)
    </li>

    <li>The whole group will pool resources to help the casualty.</li>

    <li>
      At least two (2) members the group WILL REMAIN with the casualty to give
      maximum comfort and help.
    </li>

    <li>At least two (2) members of the group will GO FOR ASSISTANCE.</li>

    <li>
      Leader of the party will decide, if the situation allows, whether any
      balance of members the party continue the walk; always providing such a
      sub-group contains competent leadership.
    </li>

    <li>
      At ALL times the leader's decision must prevail. This will dictate the
      action of the party.
    </li>
  </ul>
  <h5>Getting Assistance</h5>
  <p>The following MINIMUM WRITTEN information is required:</p>
  <ul class="simpleList">
    <li>
      Accurate grid reference and time of the occurrence. OS Locate phone app
      can verify your grid reference and What3words app can provide additional
      location information.
    </li>
    <li>Brief factual description of the accident and any problems</li>
    <li>
      Personal details of the casualty.
      <ul>
        <li>Name</li>
        <li>Age.(accurate or estimated)</li>
        <li>Home address and telephone number</li>
        <li>Prior medical history, if any, if known</li>
        <li>Blood Group</li>
        <li>Emergency contact and phone number</li>
      </ul>
    </li>
    <li>What action is proposed by the party remaining with the casualty</li>
    <li>What action proposed by any other part of that walk group.</li>
  </ul>
  Getting assistance may involve returning to high ground in order to receive mobile
  phone signal. Taking multiple phones on different networks would increase the probability
  of success.
  <h5>At the Coach</h5>
  <ul class="simpleList">
    <li>
      A minimum period of one hour is to elapse after the stated return time has
      passed before EMERGENCY measures are implemented for a non-returning group
      about whom no information has been previously received (i.e. Fell Rescue,
      called out)
    </li>
    <li>
      When an emergency has been established, other walks will continue to
      completion and ONE senior club member will be selected from senior members
      present to be club accident coordinator, to coordinate base (coach)
      activities.
    </li>
    <li>
      <strong>All</strong> returned members will stay in the close vicinity of the
      coach, unless directed otherwise by a club accident coordinator.
    </li>
    <li>
      ALL action WILL be at the discretion of the Club accident coordinator.
    </li>
    <li>
      Action taken, to be decided by the nominated person on the circumstances.
    </li>
  </ul>
</div>
