<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<dialog
  bind:this={dialog}
  class={classNames}
  style={$$props.style}
  on:click={clickedBG}
>
  <header>
    {#if icon}{icon}{/if}
    <div class="title">{title}</div>
    {#if canClose}
      <!-- Displays a unicode "heavy multiplication X". -->
      <button class="close-btn" on:click={close}>&#x2716;</button>
    {/if}
  </header>
  <main>
    <slot />
  </main>
</dialog>

<!-- See README.md for documentation on using this. -->
<script>
  import dialogPolyfill from "dialog-polyfill";
  import { createEventDispatcher, onMount } from "svelte";
  // Boolean that determines whether a close "X" should be displayed.
  export const canClose = true;
  // Optional CSS class name to be added to the dialog element.
  export const className = "";
  // Parent components can use bind:dialog to get a
  // reference so they can call show(), showModal(), and close().
  export let dialog;
  // An optional icon to render in the header before the title.
  export const icon = undefined;
  // Title text to display in the dialog header.
  export let title;
  const dispatch = createEventDispatcher();
  $: classNames = `dialog${className ? ` ${className}` : ""}`;
  onMount(() => dialogPolyfill.registerDialog(dialog));
  function close() {
    // Parent components can optionally listen for this event.
    dispatch("close");
    // This is not needed if the parent stops rendering this component.
    dialog.close();
  }
  function clickedBG(event) {
    if (event.target === dialog) {
      dialog.close();
    }
  }
</script>

<style>
  .body {
    padding: 10px;
  }
  .close-btn {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    outline: none;
    margin: 0;
    padding: 0;
  }
  dialog {
    /* These properties center the dialog in the browser window. */
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    box-shadow: 0 0 10px darkgray;
    padding: 0;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: cornflowerblue;
    box-sizing: border-box;
    color: white;
    font-weight: bold;
    padding: 10px;
    width: 100%;
  }
  main {
    padding: 10px;
  }
  .title {
    flex-grow: 1;
    font-size: 18px;
    margin-right: 10px;
  }
  dialog::backdrop,
  :global(dialog + .backdrop) {
    /* This is a transparent shade of gray. */
    /* Why is this ignored in Safari? */
    background: rgba(0, 0, 0, 0.4);
  }
</style>
