<a use:link href="/">
  <div class="banner"><span>St. Edward's Fellwalkers</span></div>
</a>

<script>
  import { link } from "svelte-spa-router";
</script>

<style lang="postcss">
  .banner {
    box-sizing: border-box;

    width: 100%;
    height: 100px;
    margin: 0;
    top: 0;
    background-image: url(/assets/image/bannerheadW.jpg);
    background-color: #ddd;
    font-size: 2em;
    line-height: 1;
    padding-left: 120px;
    z-index: 5;
    color: white;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  span {
    text-decoration: none;
    display: block;
    height: auto;
    line-height: 1;
    align-self: center;
    color: #cc3300;
  }
  /* .bannerS {
    height: 67px;
    width: 400px;
    background-image: url(image/bannerheadS.jpg);
    font-size: 1.2em;
    z-index: 5;
    color: white;
    line-height: 67px;
  } */

  div {
    font-weight: bolder;
    font-style: normal;
    /*margin-left: 180px;*/
  }
  @media only screen and (max-width: 650px) {
    .banner {
      padding-left: 120px;
    }
  }
  @media only screen and (max-width: 400px) {
    .banner {
      padding-left: 85px;
      padding-right: 0.5em;
      background-image: url(/assets/image/bannerheadS.jpg);
      height: 67px;
      font-size: 1.5em;
    }
  }
</style>
