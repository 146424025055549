import { getDaysInMonth, getDay, setDate, getDate, format } from "date-fns";
export const getDateRows = (dispMonth) => {
  const days = getDaysInMonth(dispMonth);
  const startIndex = getDay(dispMonth);
  const cDays = Math.floor((days + startIndex + 6) / 7);
  const rows = Array.from({ length: cDays }).map(() => undefined);
  Array.from({ length: days }).forEach((_, i) => {
    const index = startIndex + i;
    const c = setDate(dispMonth, i + 1);
    rows[index] = { value: getDate(c), calDate: format(c, "yyyy-MM-dd") };
  });
  return rows;
};

export const noop = () => {};

export const uuid = (() => {
  let id = 1;
  return () => {
    return ++id;
  };
})();
