<div>
  <p>
    Click on any of the links to read the PDF document on line. To download a
    document to your computer, right click on the link and select
    <i>save link as ...</i>.
  </p>
  <p>
    <a
      href="/apiServer/downloads/Club constitution April 2015.pdf"
      target="_blank">The Club Constitution</a
    >
  </p>
  <p>
    <a
      href="/apiServer/downloads/St Edwards Fellwalkers - Information for New Members & Guests 22.06.24.pdf"
      target="_blank">Information for New Member and Guests</a
    >
  </p>
  <p>
    <a
      href="/apiServer/downloads/RoleAndResponsibilitiesOfAWalkLeader.pdf"
      target="_blank">Role &amp; responsibilities of a Walk Leader</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/StEdsBusRouteJune2024.pdf" target="_blank"
      >Bus Route Map</a
    >
  </p>
  <p>
    <a
      href="/apiServer/downloads/St Edwards New Member Form & consent form 30.05.24.pdf"
      target="_blank">application form</a
    >
  </p>
  <p>
    <a href="/apiServer/downloads/StEdsFlyer2024.pdf" target="_blank"
      >Club Flyer</a
    >
  </p>
  <p>
    <a
      href="/apiServer/downloads/Generic Risk Assessment - St Edwards Fellwalkers (signed) March 2024.pdf"
      target="_blank">Generic Risk Assessment</a
    >
  </p>
</div>
