<div class="showMap">
  {#if mapIsPdf(walk.mapimg)}
    <div>
      <iframe
        src={getWalkdataURL(map)}
        id="pdf-js-viewer"
        class={orientation}
        zoom="page-fit"
        title="map"
      />
    </div>
  {:else}
    <div />
  {/if}
</div>

<script>
  import { onMount } from "svelte";
  import { getWalkdataURL, getWalkData } from "$lib/WalksMixin";
  // import "pdfjs-dist";
  export let walkId;
  let walk = {};
  let map;

  const mapIsPdf = (map) => {
    return map && map.substr(-4) === ".pdf";
  };
  const imgFile = (img) => {
    return `http://stedwardsfellwalkers.co.uk/${img}`;
  };
  let orientation;
  $: orientation = walk.orientation === "L" ? "landscape" : "portrait";

  const loadWalkData = async (walkId) => {
    console.log("ShowMap beforeMount", walkId);
    let res = await getWalkData("GetMapData", walkId);
    walk = await res.json();
    map = walk.mapimg;
    console.log("ShowMap", { walk }, map);
  };
  $: loadWalkData(walkId);
  onMount(async () => {
    loadWalkData(walkId);
  });
</script>

<style lang="postcss">
  iframe {
    &.portrait {
      width: 778px;
      height: 1160px;
    }
    &.landscape {
      width: 1100px;
      height: 840px;
    }
  }
</style>
