<div class="eventpick">
  <div id="eventSelect">
    <PickButtons list={eventsData} bind:eventId />
  </div>
  <div class="events">
    {#each eventsData as { id, start, title, dirty }}
      <div
        on:click={() => {
          eventId = id;
        }}
        class:current={id === eventId}
        class="event"
      >
        <div>{id}</div>
        <div class="index">
          <div>{start ?? ""}</div>
          <div>{title}</div>
        </div>
        <div class:dirty>{dirty ? "!" : ""}</div>
      </div>
    {/each}
  </div>
  <div id="buttons" class="block">
    <!-- <input type="button" value="Save Changes" />
    <input type="button" on:click={delEvent} value="Delete Event" /> -->
    <input type="button" on:click={addEvent} value="Add Event" />
    <!-- <input type="button" on:click={cloneEvent} value="Clone Event" /> -->
  </div>
</div>

<script>
  import PickButtons from "./_PickButtons.svelte";

  export let eventsData;

  export let eventId;
  export let addEvent;
  // export let delEvent;
  // export let cloneEvent;
  const log = (...rest) =>
    echo.log(echo.white.bgRed.bold("EventPick"), ...rest);
  $: log("_eventsPick list", eventsData);
  $: log("eventpick - events", eventId);
</script>

<style lang="postcss">
  .events {
    padding: 5px;
  }
  .event {
    display: grid;
    grid-template-columns: 2em 1fr 2em;
  }
  .event .index {
    display: grid;
    grid-template-columns: 6em 1fr;
  }
  .eventpick .current .index {
    background-color: goldenrod;
  }
  .eventpick .dirty {
    background-color: red;
    color: white;
    width: 1.5em;
    height: 1.5em;
    font-weight: bold;
    border: thin solid black;
    border-radius: 50%;
    text-align: center;
    margin-left: 1.5em;
  }
  .eventpick .event {
    cursor: pointer;
  }
  .eventpick {
    padding-right: 3em;
    min-width: 400px;
  }
</style>
