<section class="pageheader">
  {#if group}
    <h1 class="heading"><span>{group}</span></h1>
  {/if}
  {#if name}
    <h3 class="heading">{name}</h3>
  {/if}
</section>

<script>
  import { startCase } from "lodash";
  export let loc;
  let group, name, value;

  const cleanup = (xx) =>
    startCase(xx.replace(/[_-]s[_-]/g, `'s-`).replace(/[_\/]/g, " "));

  $: {
    [, group = "", name = ""] = loc.match("^/([^/]*)/?(.*)$");
    group = cleanup(group);
    name = cleanup(name);
    // [, group = "", name = "", value = ""] = loc
    //   .split("/")
    //   .map((xx) => cleanup(xx));
    // name += " " + value;
    if (group === "Home") [group, name] = ["", ""];
    if (group === "Other") [group, name] = [name, ""];
    if (group === "Gallery") name = "";
    if (/Osmap/i.test(name)) [group, name] = [name.substr(6, 10), ""];

    document.title = group + (name ? name + "|" : "");
  }
</script>

<style lang="postcss">
  h1,
  h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .pageheader {
    color: #008000;
    border-width: 0;
  }
  h1,
  h3 {
    margin: 0;
    color: #008000;
  }
</style>
