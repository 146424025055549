<div>
  {#if target}
    <a href={to} class:active={to === $location} {target}><slot /></a>
  {:else}
    <a href={to} class:active={to === $location} use:link><slot /></a>
  {/if}
</div>

<script>
  import { link, location } from "svelte-spa-router";
  export let to;
  export let target = null;
  // let current = window.location.pathname;
  to.indexOf("year") !== -1 && console.log("navbar", to, location);
</script>

<style lang="postcss">
  a,
  a:visited {
    line-height: 1.3em;
    font-size: 10px;
    /*font-weight: bold;*/
    /*transform:scale(.9,1);*/
    color: #cc3300;
    padding: 0 0 2px 0;
    text-decoration: none;
    display: inline-block;
    overflow-x: hidden;
  }
  a:hover {
    color: white;
    background-color: #cc3300;
  }
  a.active {
    font-weight: 900;
    font-size: 11px;

    &::before {
      content: "▶︎";
    }
  }
  a::before {
    content: url(/assets/image/listmark.gif);
    padding-right: 1em;
  }
</style>
