import axios from "axios";
export const getGalleryDataF = async function (...args) {
  const url = args.reduce((res, arg) => `${res}/${arg}`, "");
  let ret = await fetch(
    `https://server.stedwardsfellwalkers.co.uk/cpg${url}`
    // ...args
  );
  let data = await ret.json();
  console.log("getGalleryDataF", args, data);
  return data;
};
export const getGalleryData = function (...args) {
  const url = args.reduce((res, arg) => `${res}/${arg}`, "");
  return fetch(
    `https://server.stedwardsfellwalkers.co.uk/cpg${url}`
    // ...args
  );
};
export const uploadPhotos = function (formData) {
  return axios.post(
    `https://server.stedwardsfellwalkers.co.uk/cpg/upload`,
    formData
  );
};
export const uploadTest = function (formData) {
  const res = fetch(
    `https://server.stedwardsfellwalkers.co.uk/cpg/uploadTest`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData, // body data type must match "Content-Type" header
      // body: JSON.stringify(formData) // body data type must match "Content-Type" header
    }
  );
};

export const imageURL = function (pic, mode = "normal") {
  const name = `${pic.filepath}${pic[mode]}`;
  return pic ? `https://gallery.stedwardsfellwalkers.co.uk/${name}` : ``;
};
export const mapAlbum = (src) => {
  // process.env.NODE_ENV !== 'production'
  const loc = `https://gallery.stedwardsfellwalkers.co.uk/albums`;
  let ret = src.replace(/albums/g, loc);
  return ret;
};
export default {
  methods: {
    imageURL,
    getGalleryData,
    mapAlbum,
    uploadPhotos,
  },
};
