<span class="screenonly">
  <div class="button-group">
    <button
      tooltip={tipText}
      on:click={() => toggleVisibility(no)}
      class="w-button tooltip"
      class:visible
      >Walk
      {no}</button
    >
    <button
      class="i-button tooltip"
      tooltip={"Edit stroke for walk " + no}
      on:click={openDialog}
      style={`background-color: ${strokeColor};`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="icon-img"
      >
        <path
          d={pencil}
          style="fill: white; stroke:white; mix-blend-mode: difference;"
        />
      </svg>
    </button>

    <!-- #if is only necessary to implement the transition in
     Dialog.svelte because that only works if the <dialog>
     element is added to and removed from the DOM. -->
    {#if showDialog}
      <Dialog
        bind:dialog
        on:close={() => (showDialog = false)}
        title={`Change Settings - Walk ${no}`}
        style="min-width: 400px;"
      >
        <div class="dialog-line">
          <label for="range">Stroke Width</label>
          <input
            type="range"
            name="range"
            bind:value={strokeWidth}
            step="1"
            on:change={widthChanged}
            min="1"
            max="20"
          />
          <div class="value">{strokeWidth}</div>
        </div>
        <div class="dialog-line">
          <label for="opacity">Opacity</label>
          <input
            type="range"
            name="opacity"
            bind:value={strokeOpacity}
            step="0.1"
            on:change={opacityChanged}
            min="0.1"
            max="1"
          />
          <div class="value">{strokeOpacity}</div>
        </div>

        <div class="dialog-line">
          <label for="color">Colour</label>
          <input
            type="color"
            name="color"
            class="color"
            bind:value={strokeColor}
            on:change={colorChanged}
          />
        </div>
      </Dialog>
    {/if}
  </div>
</span>
<svelte:head>
  <link rel="stylesheet" href="/css/dialog-polyfill.css" />
</svelte:head>

<script>
  import { onMount } from "svelte";
  // import colors from "../../../components/hk./../../components/hk/colors.js";
  import { tick } from "svelte";
  import Dialog from "../../../components/hk/Dialog.svelte";
  let dialog = null;
  let showDialog = false;
  async function openDialog() {
    showDialog = true;
    await tick(); // waits for dialog to be set
    dialog.showModal();
  }
  // console.log("colours", colors);

  export let options;
  export let no;
  export let visible;
  export let toggleVisibility;
  export let optionsChanged;
  export let strokeWidth;
  export let strokeOpacity;
  export let strokeColor;
  let bType;
  let tipText;

  let pencil =
    "M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z";

  $: {
    bType = visible ? "primary" : "default";
    tipText = (visible ? "Hide" : "Show") + " walk " + no;
  }

  $: console.log(`MapRouteControl change ${no}`, visible, options, $$props);

  const colorChanged = () => {
    console.log(`colorChanged ${no}`, strokeColor);
    optionsChanged(no, "strokeColor", strokeColor);
  };
  const widthChanged = () => {
    console.log(`widthChanged ${no}`, strokeWidth);
    optionsChanged(no, "strokeWidth", Number(strokeWidth));
  };
  const opacityChanged = () => {
    console.log(`opacityChanged ${no}`, strokeOpacity);
    optionsChanged(no, "strokeOpacity", Number(strokeOpacity));
  };
</script>

<style lang="postcss">
  div.dialog-line {
    display: grid;
    grid-template-columns: 100px 200px 60px;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
  }
  div.value {
    justify-self: flex-end;
  }
  input {
    margin: 0;
    padding-right: 5px;
  }
  input.color {
    height: 3em;
    width: 8em;
    padding: 2px;
  }
  .tooltip {
    position: relative;

    &:after {
      position: absolute;
      padding: 4px;
      border: 3px solid #000;
      border-radius: 8px;
      background-color: #c5e384;
      font-size: 0.85em;
      font-weight: bold;
      color: #000;
      content: attr(tooltip);
      min-width: 80px;
      bottom: 150%;
      left: 0;
      opacity: 0;
      transition: all 0.2s ease-in-out 0.25s;
      visibility: hidden;
      z-index: 2;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      border-width: 7px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      opacity: 0;
      transition: all 0.2s ease-in-out 0.25s;
      top: -51%;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: #000;
      visibility: hidden;
    }
    &:hover {
      &:after {
        opacity: 1;
        visibility: visible;
        bottom: 120%;
      }
      &:before {
        opacity: 1;
        top: -21%;
        visibility: visible;
      }
    }
  }
  .button-group {
    margin-right: 0.5em;
  }
  .w-button {
    padding-left: 3px;
    padding-right: 3px;
    height: 36px;
  }
  .w-button.visible {
    background-color: #409eff;
    border-color: #409eff;
  }
  .i-button {
    padding: 8px;
    & svg {
      width: 16px;
      max-height: 32px;
    }
  }
  @media print {
    .screenonly {
      display: none;
    }
  }
</style>
