<!-- <svelte:head>
  <script
    src="https://labs.os.uk/public/os-api-branding/v0.2.0/os-api-branding.js"></script>
  <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>
  <script
    src="https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.5.0/build/ol.js"></script>

  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/proj4js/2.7.0/proj4.js"></script>
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/proj4leaflet/1.0.2/proj4leaflet.min.js"></script>
</svelte:head> -->
<!-- <div class="frame">
  <div class="spacer">spacer</div> -->

<div class="osmap">
  <div class="mapHolder">
    <div id="map" />
  </div>
  {#if layers}
    <div class="selectorRegion screenonly">
      {#each layers.slice(1) as layer}
        <MapRouteControl
          {...layer}
          {toggleVisibility}
          optionsChanged={changeValue}
        />
      {/each}
    </div>
  {/if}
</div>

<!-- </div> -->
<script>
  /* global OpenSpace, OpenLayers, L */
  import { onMount, onDestroy } from "svelte";
  import { range } from "lodash";
  import MapRouteControl from "./_MapRouteControl.svelte";
  import { getWalkData, getWalkdataURL } from "$lib/WalksMixin";
  const colors = [
    "white",
    "#ff0000",
    "#0000ff",
    "#00ff00",
    "#ff8c00",
    "#4b0082",
  ];
  export let params = {};
  let { walkId, no, noRoutes } = params;
  let secondaryHeading = "";
  let gpxJ;
  let osMap = null;
  no = parseInt(no);
  let layers = range(0, parseInt(noRoutes ?? 6) + 1).map((no) => ({
    visible: false,
    no: no,
    strokeColor: colors[no],
    strokeWidth: 5,
    strokeOpacity: 1,
  }));

  $: console.log("layers now", layers);
  export function getName() {
    return gpxJ.area;
  }
  onMount(async () => {
    let res = await getWalkData("getRoutesGpxJ", walkId);
    let data = await res.json();
    console.log("OSMap onMount data", data, layers);
    gpxJ = data;
    secondaryHeading = {
      main: walkId,
      sub: gpxJ.area,
    };
    layers.forEach((layer, no) => {
      layer.gpxFile = data?.[no]?.gpxFile;
    });
    const gpx = data[no];

    let { gpxFile, cent, end } = gpx;

    console.log("OSMap onMount", gpx, walkId, res, data);

    // ({ lat, lng, gpxJ, end } = data);
    console.log("OSmap gpxJ", { walkId, no, cent, gpxFile, gpxJ, end });
    var apiKey = "BO2rqh5FkfJQ9ZOj5IxKsVZyQnfFmqTj";
    var serviceUrl = "https://api.os.uk/maps/raster/v1/zxy";

    // Setup the EPSG:27700 (British National Grid) projection.
    proj4.defs(
      "EPSG:27700",
      "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs"
    );
    ol.proj.proj4.register(proj4);

    var tilegrid = new ol.tilegrid.TileGrid({
      resolutions: [
        896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75,
      ],
      origin: [-238375.0, 1376256.0],
    });

    // Initialize the map object.
    osMap = new ol.Map({
      layers: [
        new ol.layer.Tile({
          source: new ol.source.XYZ({
            url: serviceUrl + "/Leisure_27700/{z}/{x}/{y}.png?key=" + apiKey,
            projection: "EPSG:27700",
            tileGrid: tilegrid,
          }),
        }),
      ],
      target: "map",
      view: new ol.View({
        projection: "EPSG:27700",
        extent: [-238375.0, 0.0, 900000.0, 1376256.0],
        resolutions: tilegrid.getResolutions(),
        minZoom: 0,
        maxZoom: 9,
        center: [427343, 473857.5],
        zoom: 7,
      }),
    });
    let starts = {};
    const mergeClosePoints = (a, b) => {
      let s1 = a[0] - b[0];
      let s2 = a[1] - b[1];
      const dist = Math.sqrt(s1 * s1 + s2 * s2);
      return dist < 15 ? a : b;
    };
    for (let no = 1; no <= noRoutes; no++) {
      let b = data[no].start;
      for (let i = 1; i < no; i++) {
        data[no].start = mergeClosePoints(data[i].start, data[no].start);
      }
      let start = JSON.stringify(data[no].start);
      starts[start] = [...(starts[start] ?? []), no];
    }
    console.log("starts", starts, -2 * -2);
    for (let no = 1; no <= noRoutes; no++) {
      let start = starts[JSON.stringify(data[no].start)];
      let index = start.findIndex((item) => item === no);
      updateLayer(no, { pos: [data[no].start, no, index, start.length] });
      addMarkerLayer(data[no].start, no, index, start.length);
      addRouteLayer(no);
    }
    addMarkerLayer(data[no].end, 0);

    showLayer(no);
    console.log("osMap ready", osMap);
  });

  // let route = (no) => {
  //   return getWalkdataURL(
  //     walkId.substr(0, 4),
  //     walkId,
  //     `data-${walkId}-walk-${no}.gpx`
  //   );
  // };

  let toggleVisibility = (no) => {
    let lay = layers[no];
    let lgpx = findLayerById("route-" + no);

    if (!lgpx) addRouteLayer(no);
    else if (lgpx?.getVisible()) {
      setRouteVisibility(no, false);
    } else showLayer(no);
    // console.log(`lgpx[${no}] now`, lay);
    osMap?.getLayers().forEach((l) => {
      if (l.routeNo !== undefined)
        console.log("layer has", l.id, l.getVisible(), l.zIndex);
    });
  };
  const findLayerById = (id) => {
    let ret;
    osMap?.getLayers().forEach((l) => {
      if (l.id === id) ret = l;
    });
    return ret;
  };
  let showLayer = (no) => {
    let lgpx = findLayerById("route-" + no);
    console.log(`showing ${no}`, lgpx);
    if (!lgpx) addRouteLayer(no);
    setRouteVisibility(no, true);
    return;
  };
  const addMarkerLayer = (startCoord, no, index = 0, of = 1) => {
    // Add a point(s) layer to the map.
    let xtra = of - 1;
    let angle = xtra * 0.6;
    let seg = angle > 0 ? angle / xtra : angle;
    let rot = angle / -2 + index * seg;
    let offset = of * 0 * index;
    offset = 0.5;
    console.log("add marker", {
      no,
      index,
      of,
      xtra,
      seg,
      angle,
      rot,
      offset,
      layer: layers[no],
    });
    const svg =
      `data:image/svg+xml;base64,` +
      btoa(`<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 141.7 141.7">
  <path d="m70.9 7.1c-24.8 0-44.9 16.5-44.9 48.4s44.9 79.1 44.9 79.1 44.9-47.2 44.9-79.1c-.1-31.9-20.2-48.4-44.9-48.4z" fill="${layers[no].strokeColor}"/>
  <circle cx="70.9" cy="50" r="30" fill="#fff" />
  <text x="53" y="70" style="font-family: sans-serif;font-size:60px; stroke:none; fill:black">${no}</text>
</svg>`);
    const src = no === 0 ? `/assets/image/marker-finish.svg` : svg;
    console.log("marker", no, src);
    let marker = new ol.layer.Vector({
      type: "marker",
      source: new ol.source.Vector({
        features: [
          new ol.Feature({
            geometry: new ol.geom.Point(startCoord),
          }),
        ],
      }),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [offset, 1],
          rotation: rot,
          src: src,
          // src: `/assets/image/marker-${
          //   no === 0 ? "finish" : "start-" + no
          // }.svg`,
          scale: 0.75,
          opacity: 1,
        }),
      }),
    });
    marker.routeNo = no;
    // marker.type = "marker";
    marker.setVisible(no === 0);
    marker.setZIndex(200 + no);
    marker.id = "marker-" + no;

    updateLayer(no, { visible: false });
    osMap.addLayer(marker);
    return marker;
  };
  let zIndex = 100;
  const addRouteLayer = (no) => {
    let lay = layers[no];
    const start = gpxJ[no].start;
    var lgpx = new ol.layer.Vector({
      source: new ol.source.Vector({
        url: getWalkdataURL(lay.gpxFile),
        // url: route(no),
        format: new ol.format.GPX(),
      }),
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: lay.strokeColor,
          width: lay.strokeWidth,
        }),
      }),
    });
    lgpx.routeNo = no;
    lgpx.id = "route-" + no;
    lgpx.setOpacity(lay.strokeOpacity);

    // lgpx.type = "route";
    osMap.addLayer(lgpx);
    lgpx.once("change", function (evt) {
      var extent = lgpx.getSource().getExtent();
      osMap.getView().fit(extent, { maxZoom: 7 });
    });
    setRouteVisibility(no, true);
    updateLayer(no, { visible: true });
    console.log(`added route ${no}`, lay);
    return { lgpx };
  };
  const setRouteVisibility = (no, visible) => {
    const lgpx = findLayerById("route-" + no);
    lgpx?.setVisible(visible);
    lgpx?.setZIndex(++zIndex);
    let marker = findLayerById("marker-" + no);
    marker?.setVisible(visible);
    // marker?.setZIndex(zIndex);

    updateLayer(no, { visible });
  };
  const updateLayer = (no, props = {}) => {
    let newLayer = { ...layers[no], ...props };
    layers = [...layers.slice(0, no), newLayer, ...layers.slice(no + 1)];
  };
  let hideLayer = (no) => {
    let lay = layers[no];
    console.log(`hiding ${lay.no}`, lay);
    // lay.lgpx.visible=false;
    let lgpx = findLayerById("route-" + no);

    if (lgpx) osMap.removeLayer(lgpx);
    // if (lay.lgpx) lay.osMap.removeLayer(`gpx${lay.no}`);
    lay.lgpx = null;
    if (lay.marker) osMap.removeMarker(lay.marker);
  };

  let changeValue = (no, att, val) => {
    // if (att !== "strokeColor") val = Number(val);
    updateLayer(no, { [att]: val });
    layers[no][att] = val;
    console.log("layyer now", no, att, val, layers[no]);
    let lgpx = findLayerById("route-" + no);

    // osMap.addLayer({ ...osMap.removeLayer(lgpx) });
    osMap.removeLayer(lgpx);
    showLayer(no);
    let marker = findLayerById("marker-" + no);

    // osMap.addLayer({ ...osMap.removeLayer(lgpx) });
    osMap.removeLayer(marker);
    addMarkerLayer(...layers[no].pos);
    showLayer(no);
  };
</script>

<style lang="postcss">
  .mapHolder {
    position: relative;
  }
  #map {
    border: 1px solid black;
    position: relative;
    position: absolute;
    top: 0;
    bottom: 0;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
  }
  .osmap {
    display: grid;
    grid-template-rows: minmax(0, 1fr) 40px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .selectorRegion {
    flex: 0 0 auto;
  }

  .selectorRegion {
    padding: 3px;
    background-color: #ccc;
    display: flex;
    flex-direction: row;
  }
  @media print {
    .screenonly {
      display: none;
    }
  }
</style>
