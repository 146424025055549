<table class="headed">
  <tr class="head">
    <td colspan="2"><strong>Chair</strong></td>
  </tr>
  <tr class="entry">
    <td>Helen Kay<br /></td>
    <td>
      <br />
      <MailToLink to="chair@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Secretary</strong></td>
  </tr>
  <tr class="entry">
    <td>Paul Stockdale<br /></td>
    <td>
      <MailToLink to="secretary@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Walks Co-ordinator</strong></td>
  </tr>
  <tr class="entry">
    <td>Gordon Philpott<br /></td>
    <td>
      0191 252 9345<br />
      <MailToLink to="walks@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Treasurer</strong></td>
  </tr>
  <tr class="entry">
    <td>Iain Ord<br /></td>
    <td>
      <MailToLink to="treasurer@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Bookings Secretary</strong></td>
  </tr>
  <tr class="entry">
    <td>Clare Sarin<br /></td>
    <td>
      <br />
      <MailToLink to="bookings@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Membership Secretary</strong></td>
  </tr>
  <tr class="entry">
    <td>Ray Salkeld<br /></td>
    <td>
      07766 925 108<br />
      <MailToLink to="membership@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
  <tr class="head">
    <td colspan="2"><strong>Social Secretary</strong></td>
  </tr>
  <tr class="entry">
    <td>Judith Green<br /></td>
    <td>
      <MailToLink to="social@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>

  <tr class="head">
    <td colspan="2"><strong>Webmaster</strong></td>
  </tr>
  <tr class="entry">
    <td>Aidan Nichol<br /></td>
    <td>
      <MailToLink to="webmaster@stedwardsfellwalkers.co.uk" />
    </td>
  </tr>
</table>

<script>
  import MailToLink from "@components/MailToLink.svelte";
</script>

<style>
  table {
    border-collapse: collapse;
  }

  tr {
    background-color: none;
  }
  tr.head {
    background-color: #bbbbbb;
  }
  & td {
    height: 1em;
    vertical-align: middle;
  }

  tr td {
    padding: 3px 10px 3px 10px;
  }
  tr.entry td {
    height: 40px;
    vertical-align: middle;
  }

  td {
    border: 1px solid green;
  }
</style>
