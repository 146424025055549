<ul class="simpleList">
  <li>
    The bus normally leaves Tynemouth at 8.00 am (7.00 am during the summer). It
    goes along the Broadway to Whitley Bay, up Front Street Monkseaton and along
    the old coast road to South Gosforth picking up at various points along the
    way. See the <a href="/apiServer/downloads/StEdsBusRoute2021.pdf"
      >Bus Route</a
    >  in the Downloads page for detailed information.
  </li>
  <li>
    You may put your rucksack and bag with a change of clothes in the boot of
    the bus.
  </li>
  <li>
    After the last pick-up point, a check is made that the people on the bus
    match those that booked.
  </li>
  <li>
    An announcement will be made describing the walks for the day, the breakfast
    stop and arrangements for the evening stop after the walks.
  </li>
  <li>
    There is usually a varied mix of communications to members that get
    circulated around the bus, e.g. minutes of meetings, walk maps, press
    cuttings etc.
  </li>
  <li>
    The most important item that always gets circulated are the maps showing the
    day’s walks. There will be 5 walks on a walk day, to accommodate the various
    levels of fitness and ability among members on the day. They can also be
    seen on the website before the walk day. Each person needs to select which
    walk they will go on. See the separate section for advice on <a
      href="/#/Walk_Organization/choice_of_walk">Choice of Walk</a
    >.
  </li>
  <li>
    Somebody will come around and note your decision. It is important that you
    go with that walk. You must inform the relevant walk leaders if you have a
    change of mind.
  </li>
  <li>
    The bookings secretary usually goes around the bus collecting in outstanding
    payment. Bookings for future walks can be made at this time. It is preferred
    that these are kept to a minimum and bookings are made by other email or
    telephone.
  </li>
  <li>The bus usually makes a 30 minute stop around 9.00 for breakfast.</li>
  <li>
    We usually start walking between 10:00am and 11.00am. An announcement will
    advise that the start point for each walk has been reached.
  </li>
  <li>
    Having enjoyed a good days walking you will arrive back at the bus and can
    change out of your walking gear into casual clothes for the evening.
  </li>
  <li>
    Adjourn to the local hostelry or tea room and socialise with your fellow
    walkers. Generally, we aim to stay there for a while giving everybody the
    chance to have a meal but if food is not available we have a shorter stay
    and stop somewhere else on the way home for a meal.
  </li>
  <li>
    The bus either retraces it's route back home dropping off people on the way,
    OR if it returns via the Tyne Tunnel it follows the pick-up route to South
    Gosforth. The return times will vary but to be safe you should plan on the
    bus arriving back in Tynemouth or South Gosforth at approx. 9.00 pm.
  </li>
</ul>
