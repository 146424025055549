<div class="gallery">
  <dialog bind:this={renameAlbumDialog}>
    <div>Rename Album <b>{curAlbumName}</b></div>
    <form method="dialog">
      <input bind:value={album.title} type="text" size="40" />
      <div>
        <button value="cancel" ord="secondary">cancel</button>
        <button on:click={renameAlbum} ord="primary">OK</button>
      </div>
    </form>
  </dialog>
  <dialog bind:this={deleteAlbumDialog}>
    <form method="dialog">
      <h3>Confirm deletion of {album?.title}</h3>
      <p>
        About to permanently this album and {album.count} pictures.
      </p>
      <p>This can't be undone</p>

      <p>Proceed?</p>
      <div>
        <button value="cancel" ord="secondary">No - Keep it</button>
        <button on:click={deleteAlbum} ord="primary">Yes - delete it</button>
      </div>
    </form>
  </dialog>
  <m-accordion class="years">
    {#each sortedAlbumLists as [year, list], i}
      <details open={year === curYear}>
        <summary on:click={() => pickYear(year, year === curYear)}
          >{year}
          <m-badge count={list?.length} /></summary
        >
        {#each list as album, j}
          <button
            type="button"
            on:click={push(`/hk/gallery/maintAlbum/${album.aid}`)}
            class="album"
            class:hidden={album.hidden}
          >
            <div>
              <m-icon name="ban" class="ban" />
              {@html album.title}<m-badge
                count={album.count}
                class="albumBadge"
              />
            </div>
            <div class="buttons">
              <button
                on:click|stopPropagation={() => showRenameAlbum(album)}
                title="Rename Album"
                ord="secondary"><m-icon name="pencil" /></button
              >
              <button
                on:click|stopPropagation={() => hideAlbum(album, i, j)}
                title={album.hidden ? "Show Album" : "Hide Album"}
                ord="secondary"><m-icon name="ban" /></button
              >
              <button
                on:click|stopPropagation={() => showDeleteAlbum(album, i, j)}
                title="Delete Album"
                ord="secondary"><m-icon name="trash" /></button
              >
            </div>
          </button>
        {/each}
      </details>
    {/each}
  </m-accordion>
</div>

<script>
  import { onMount } from "svelte";
  import _ from "lodash";
  import { push } from "svelte-spa-router";

  import {
    getGalleryDataF,
    doGalleryChanges,
  } from "@components/galleryMixin.mjs";

  export let params;
  // biome-ignore lint/suspicious/noConfusingLabels: <explanation>
  $: curYear = params?.year;

  let yearList = [];
  let album = { title: "" };
  let sortedAlbumLists = [];
  let deleteAlbumDialog;
  let renameAlbumDialog;
  let curAlbumName = "???";
  // $: console.warn("yearList:", yearList);
  // $: console.warn("sortedAlbumLists:", sortedAlbumLists);
  // $: console.warn("curYear:", curYear);
  // $: console.warn("curAlbum:", album);
  // $: console.warn("curAlbumName:", curAlbumName);

  onMount(async () => {
    yearList = await getGalleryDataF("getYears");
    const albums = await getGalleryDataF("getAllAlbums");
    const albumLists = _.groupBy(albums, "year");
    const pairs = _.toPairs(albumLists ?? {});

    sortedAlbumLists = _.orderBy(
      pairs,
      (p) => {
        const key = p[0];
        return /^\d+$/.test(key) ? key : `0${key}`;
      },
      "desc",
    );
  });

  // let getAlbums = async (year) => {
  //   curYear = year;
  //   let data = await getGalleryDataF("getAlbumList", year);
  //   data.forEach((alb) => {
  //     alb.title = alb.title.replace("&amp;", "&");
  //   });

  //   albumList = data;
  // };
  const pickYear = (year, active) => {
    if (!active) push(`/hk/gallery/maint/${year}`);
    else push("/hk/gallery/maint");
  };

  async function renameAlbum() {
    await doGalleryChanges("renameAlbum", album);
    const y = sortedAlbumLists.findIndex((y) => y[0] === album.year);

    sortedAlbumLists[y] = [...sortedAlbumLists[y]];
    sortedAlbumLists = [...sortedAlbumLists];
  }
  async function hideAlbum(album) {
    album.hidden = album.hidden ? 0 : 1;
    const { count: changed } = await doGalleryChanges("hideAlbum", album);
    console.log("hideGallery", { changed, ...album });
    const y = sortedAlbumLists.findIndex((y) => y[0] === album.year);
    sortedAlbumLists[y] = [...sortedAlbumLists[y]];
    sortedAlbumLists = [...sortedAlbumLists];
  }
  async function showRenameAlbum(albm) {
    album = albm;
    curAlbumName = album.title;
    renameAlbumDialog.showModal();
  }
  async function showDeleteAlbum(albm) {
    album = albm;
    deleteAlbumDialog.showModal();
  }
  async function deleteAlbum() {
    await doGalleryChanges("deleteAlbum", album);
    const y = sortedAlbumLists.findIndex((y) => y[0] === album.year);
    const yearsAlbums = [...sortedAlbumLists[y][1]];
    const a = yearsAlbums.findIndex((a) => a.aid === album.aid);
    yearsAlbums.splice(a, 1);
    sortedAlbumLists[y][1] = yearsAlbums;
    sortedAlbumLists = [...sortedAlbumLists];
  }
</script>

<style css="postcss">
  :root {
    --fontSize: 1em;
    --albumWidth: 12.5em;
    --liWidth: 20em;
  }

  @media (max-width: 500px) {
    :root {
      --fontSize: 0.7em;
      --albumWidth: 9em;
      --liWidth: calc(100vw-6em);
    }
  }
  .albumBadge {
    background-color: rgba(241, 222, 174, 0.98);
    color: #888;
    position: relative;
    top: 1px;
  }

  .gallery {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .album {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .album > div {
    position: relative;
  }
  .album.hidden > div {
    opacity: 0.5;
  }

  .album m-icon.ban {
    display: inline-block;
    font-size: 1.4em;
    position: relative;
    top: 0.2em;

    font-weight: bold;
    color: red;
    opacity: 1;
  }
  .album:not(.hidden) m-icon.ban {
    color: white;
    opacity: 0;
  }

  .album .buttons {
    display: none;
  }
  .buttons button {
    background-color: white;
  }
  .album:hover .buttons {
    display: block;
  }

  .years div {
    min-width: 4em;
  }
  .album:hover {
    background-color: rgba(244, 237, 220, 0.98);
    width: fit-content;
    z-index: 200;
    max-width: none;
    overflow: visible;
    text-decoration: none;
    color: #444;
  }
</style>
