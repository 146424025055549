<div>
  <ul class="simpleList">
    <li>
      It is the responsibility of the walk organizer for a particular day that a
      variety of walks are offered, in terms of distance, severity and the time
      available. These will vary according to local conditions but are
      typically: -
    </li>
    <ol>
      <li>
        A 15-16 mile walk which will tend to reach the higher hills and is
        intended for the stronger walker.
      </li>
      <li>
        A 12-13 mile walk that will still tend to go fairly high but over a
        shorter distance.
      </li>
      <li>
        A 9-11 mile walk, which is similar to the 2nd walk with perhaps, less
        height gained.
      </li>
      <li>
        A 6-9 mile walk that is essentially low level with minimal hill walking.
      </li>
      <li>A 4-6 mile walk with little or no hill walk.</li>
    </ol>
    <li>
      It is the responsibility of the individual walker to choose a walk that
      they believe they can physically undertake IN THE AVAILABLE TIME AND IN
      THE PREVAILING CONDITIONS without undue stress to themselves and delays to
      the group as a whole.
    </li>
    <li>
      A brief written description and map showing the walks and grading will be
      available on the bus prior to the walk to aid members’ walk selection.
    </li>
    <li>
      The walk organizer and other members will always be willing to try to
      answer any questions about the walk.
    </li>
    <li>
      When selecting a walk, care should be taken to consider not only the
      distance but also ascents/descents to be made, the prevailing weather
      conditions, the terrain they would be walking in and the time available.
    </li>
    <li>
      If necessary, walkers will be vetted on their ability, physically, to
      tackle a given walk.
    </li>
    <li>
      A new member's first walk with the Club will be restricted to one of the
      less strenuous walks.
    </li>
    <li>
      The appointed walk leader for the day will head the list going round the
      bus for each walk so that everybody knows who the walk leader is as they
      select the walk they would prefer to tackle.
    </li>
    <li>
      If a walk fails to attract a minimum of 5 members it will only take place
      at the discretion of at least three Committee members. This is a safety
      precaution. It is sometimes necessary to ask people to join a different
      walk to the one they selected in order that the minimum number is
      attained.
    </li>
  </ul>
</div>
