<div>
  <p>
    As a member your actions can contribute to the safety and enjoyment of the
    whole group
  </p>
  <ul class="simpleList">
    <li>
      You will be fully and properly equipped for Club walks. See <a
        href="#/guidelines/personal_equipment/"
      >
        Personal Equipment</a
      >. Especially make sure you have sufficient liquid and are prepared for
      changes in the weather.
    </li>
    <li>
      Choose a walk which you consider you can finish <strong
        >on the day, in the time allowed</strong
      >, taking into consideration the weather, the expected conditions
      underfoot and your personal health and fitness. Don't learn the hard way
      about the need to make a suitable choice of walk
      <em>before</em> starting out. See
      <a href="#/Walk_Organization/choice_of_walk/"> choice of walk</a> for more
      on this subject.
    </li>
    <li>
      If a walk leader considers a walk well beyond your ability on the day,
      then accept the leader's decision, and choose a less strenuous walk that
      day.
    </li>
    <li>
      The walk leader is concerned with the safety and enjoyment of the whole
      group. Give him or her your full support and help in any way you can.
      Accept a leader's decision, after consultation where necessary, as final.
    </li>
    <li>
      Do your utmost to stay with the main body of the walking group. If you
      find you are having difficulty in keeping up for any reason then let the
      leader know. Don't get too far ahead of the main body, slow down and wait.
    </li>
    <li>
      If another walker is having trouble think what you can do to help. Often,
      moral support is all that is needed.
    </li>
    <li>
      Conduct yourselves, at all times, in a manner which will enhance the good
      name of the Club.
    </li>
    <li>
      14 - 16 year olds must be accompanied by a responsible adult. If that is
      you, then you need to be able to look after them and be responsible for
      them at ALL times.
    </li>
  </ul>
  <p>
    If, through your actions or lack of preparation, you put other walkers in
    danger, then YOU will be held personally responsible for the outcomes due to
    such actions or lack of personal preparation. You are expected to behave as
    a member of the group, not as an individual.
  </p>

  <p>
    The Club Guidelines are for the safety and well being of members and
    repeated serious breaches of them by a member could result in disciplinary
    action being taken by the Committee. At worst this may lead regrettably to a
    member being expelled from the club. o avoid confusion in the event of an
    incident, the walk leader only determines appropriate action after
    consultation with the group as necessary.
  </p>
</div>
